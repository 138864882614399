@import url("http://fast.../fonts.net/t/1.css?apiType=css&projectid=e0cb3048-2922-4f9c-b87e-60554c25de6d");
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

body {
  margin: 0; }

form {
  margin: 0; }

article,
aside,
footer,
header,
nav,
section,
summary {
  display: block; }

a {
  background-color: transparent;
  cursor: pointer; }

a:active,
a:hover {
  outline: 0; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto; }

* {
  box-sizing: border-box; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

h1, h2, h3, h4, h5, h6, p, ul, ol, blockquote {
  margin: 0;
  padding: 0; }

@font-face {
  font-family: "Foundry Form Serif W01 Bk";
  src: url("../fonts/e24d325b-0054-4b4c-a311-2facd8dc71a0.eot?#iefix");
  src: url("../fonts/e24d325b-0054-4b4c-a311-2facd8dc71a0.eot?#iefix") format("eot"), url("../fonts/18d960d6-a221-466a-93ed-985b622b555a.woff2") format("woff2"), url("../fonts/4ad9eeb9-471c-4ff6-add7-b133eb5c305d.woff") format("woff"), url("../fonts/89a81ca9-b66c-4c19-907c-015dfbf51786.ttf") format("truetype"), url("../fonts/304e89d9-bcf4-4d1b-b825-710be9b753cb.svg#304e89d9-bcf4-4d1b-b825-710be9b753cb") format("svg"); }

@font-face {
  font-family: "Foundry Form Serif W01 Demi";
  src: url("../fonts/f9948d09-78e1-488d-8486-e548de784a84.eot?#iefix");
  src: url("../fonts/f9948d09-78e1-488d-8486-e548de784a84.eot?#iefix") format("eot"), url("../fonts/8c34c3ae-e4e2-40db-b0e2-7556a6af46ca.woff2") format("woff2"), url("../fonts/dafbe110-7c50-42e0-b516-2d23d7cd8679.woff") format("woff"), url("../fonts/7bd7458d-56b1-4322-b015-0afefc61325f.ttf") format("truetype"), url("../fonts/4e80abef-0408-4510-b778-d15ecb007831.svg#4e80abef-0408-4510-b778-d15ecb007831") format("svg"); }

@font-face {
  font-family: "Foundry Form Sans W01 Bk";
  src: url("../fonts/f47350d4-406f-43ce-9e55-11f9180dc806.eot?#iefix");
  src: url("../fonts/f47350d4-406f-43ce-9e55-11f9180dc806.eot?#iefix") format("eot"), url("../fonts/4f8e8cca-1895-42fb-aa0b-f53cc3612c42.woff2") format("woff2"), url("../fonts/1ee3b807-d078-44ab-8dae-165810404a68.woff") format("woff"), url("../fonts/9830fc3f-8ae8-4a40-86b8-fcab056a4919.ttf") format("truetype"), url("../fonts/2eefacac-475f-4162-9a23-ee40b6047b6c.svg#2eefacac-475f-4162-9a23-ee40b6047b6c") format("svg"); }

@font-face {
  font-family: "Foundry Form Sans W01 Demi";
  src: url("../fonts/21764085-1a53-4f7a-aee3-9760b65afb2c.eot?#iefix");
  src: url("../fonts/21764085-1a53-4f7a-aee3-9760b65afb2c.eot?#iefix") format("eot"), url("../fonts/e97040db-b08c-43ad-8249-d4bb858f616f.woff2") format("woff2"), url("../fonts/cab63fb7-fb8b-4354-a23d-72f049af7112.woff") format("woff"), url("../fonts/49659ab4-3941-4cdf-84a4-bc5844eb06af.ttf") format("truetype"), url("../fonts/c44e623a-995d-45f9-91c6-d0d88332c7e7.svg#c44e623a-995d-45f9-91c6-d0d88332c7e7") format("svg"); }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

.date-section--content {
  margin: 0 auto; }

.our-story--header:after, .guide-filters--outer:after, .documents-list:after {
  content: "";
  display: table;
  clear: both; }

.std-btn svg {
  border-radius: 100%; }

.date-section {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.cf7 input[type=text], .cf7 input[type=email], .cf7 input[type=tel], .cf7 textarea {
  width: 100%;
  padding: 0 1rem;
  border: 1px solid #d9d3d1;
  background-color: #fff;
  color: #312224; }

/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out; }

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel .owl-refresh .owl-item {
  display: none; }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d; }

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto; }

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img {
  transform-style: preserve-3d; }

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1; }

html {
  font-size: 57.5%; }
  @media (min-width: 768px) {
    html {
      font-size: 62.5%; } }

input[type=text], input[type=email], input[type=submit], input[type=button], select, option, button, textarea {
  -webkit-appearance: none;
  border-radius: 0; }

body, input, select, option, button, textarea {
  font-family: "Foundry Form Sans W01 Bk", Arial, sans-serif, sans-serif;
  color: #312224;
  -webkit-font-smoothing: antialiased; }

body {
  height: 100% !important;
  background: url(../images/mobile-bg.jpg) top left no-repeat; }
  @media (min-width: 680px) {
    body {
      background-size: 100% auto; } }

body.become-a-guide-page {
  background: url(../images/become-guide-bg.jpg) top center no-repeat; }

body.single-partner {
  background-size: auto 650px; }
  @media (min-width: 680px) {
    body.single-partner {
      background-size: auto 800px; } }
  @media (min-width: 960px) {
    body.single-partner {
      background-size: 100% auto; } }

a {
  text-decoration: none; }

a[href^=tel] {
  color: inherit;
  text-decoration: none; }

.red-btn {
  background: #E83E31;
  color: #fff;
  display: inline-block;
  padding: 0 10px; }

.white {
  background: #fff; }

.black {
  background: #312224; }

.red {
  background: #f33633; }

.grey {
  background: #9D9D9C; }

.fl-left {
  float: left !important; }

.fl-right {
  float: right !important; }

.outer {
  position: relative;
  overflow: hidden; }
  .outer:after {
    content: "";
    display: table;
    clear: both; }

.outer-padded {
  position: relative;
  overflow: hidden;
  padding: 0 0 3rem; }
  .outer-padded:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 1200px) {
    .outer-padded {
      padding: 0 0 6rem; } }

.outer-50 {
  position: relative;
  overflow: hidden;
  margin: 0 0 3rem; }
  .outer-50:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 1200px) {
    .outer-50 {
      margin: 0 0 6rem; } }

.inner, .inner-full, .inner-50, .inner-padded {
  position: relative;
  clear: both; }
  .inner:after, .inner-full:after, .inner-50:after, .inner-padded:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 960px) {
    .inner, .inner-full, .inner-50, .inner-padded {
      max-width: 1200px;
      margin: 0 auto; } }
  @media (min-width: 1200px) {
    .inner, .inner-full, .inner-50, .inner-padded {
      max-width: 1200px; } }

.inner {
  padding: 0 1rem; }
  @media (min-width: 680px) {
    .inner {
      padding: 0 2rem; } }
  @media (min-width: 1200px) {
    .inner {
      padding: 0; } }

.inner-50 {
  padding: 0 2rem;
  margin: 0 auto 3rem; }
  @media (min-width: 1200px) {
    .inner-50 {
      padding: 0;
      margin: 0 auto 6rem; } }

.inner-padded {
  padding: 3rem 2rem; }
  @media (min-width: 1200px) {
    .inner-padded {
      padding: 6rem 0;
      margin: 0 auto; } }

.std-btn {
  display: block;
  float: left;
  text-transform: uppercase;
  line-height: 2rem;
  color: #312224;
  text-decoration: none;
  font-size: 14px;
  font-size: 1.4rem; }
  @media (min-width: 680px) {
    .std-btn {
      color: #f33633; } }
  .std-btn svg {
    display: none;
    margin: 0 0 0 1rem;
    fill: #fff;
    display: block;
    width: 2rem;
    height: 2rem;
    background-color: #f33633;
    float: right; }
    @media (min-width: 680px) {
      .std-btn svg {
        display: block; } }
  @media (min-width: 960px) {
    .std-btn:hover {
      color: #312224; }
      .std-btn:hover svg {
        transition: all 0.25s ease-in-out;
        transform: rotate(450deg);
        background-color: #312224; } }

/* ====================

HEADER

==================== */
.page-header:after {
  content: "";
  display: table;
  clear: both; }

@media (min-width: 1200px) {
  .page-header {
    max-width: 1200px;
    margin: 0 auto 20px;
    margin: 0 auto 2rem; } }

.header-top {
  font-size: 14px;
  font-size: 1.4rem;
  float: right; }
  .header-top .red-btn {
    line-height: 3rem;
    height: 3rem; }
  @media (min-width: 680px) {
    .header-top {
      margin-right: 2rem; } }
  @media (min-width: 960px) {
    .header-top {
      margin-bottom: 5rem;
      margin-right: 0; } }

.mobile-search-toggle {
  display: inline-block;
  vertical-align: top; }
  .mobile-search-toggle svg {
    fill: #405261;
    width: 30px;
    width: 3rem;
    height: 30px;
    height: 3rem;
    background-color: #fff; }

div.search-form {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  background-color: #1C1E41; }
  div.search-form input[type="search"] {
    border: 1px solid #ececec;
    box-sizing: content-box;
    display: block;
    margin: 0 auto;
    padding: 1rem;
    width: 90%; }
  @media (min-width: 960px) {
    div.search-form {
      display: none;
      float: right; }
      div.search-form input {
        border: none;
        height: 3rem;
        line-height: 3rem;
        padding: 0; } }

form.search-form {
  padding: 1rem;
  margin-bottom: 0; }

.bmg-logo {
  float: left;
  margin: 5rem 0 1.5rem 1rem; }
  @media (min-width: 680px) {
    .bmg-logo {
      margin: 5rem 0 2.5rem 2rem; } }
  @media (min-width: 1200px) {
    .bmg-logo {
      margin: 5rem 0 2.5rem 0; } }

.main-menu-toggle {
  display: block;
  width: 30px;
  width: 3rem;
  height: 25px;
  height: 2.5rem;
  float: right;
  clear: right;
  margin: 30px 10px 0 0;
  margin: 3rem 1rem 0 0; }
  @media (min-width: 680px) {
    .main-menu-toggle {
      margin: 30px 20px 0 0;
      margin: 3rem 2rem 0 0; } }
  @media (min-width: 960px) {
    .main-menu-toggle {
      display: none; } }

.main-nav {
  display: none; }
  @media (min-width: 960px) {
    .main-nav {
      display: block;
      float: right;
      clear: right;
      margin-right: 2rem; }
      .main-nav ul {
        list-style: none; }
      .main-nav li {
        float: left;
        margin-left: 2rem; }
        .main-nav li:first-child {
          margin-left: 0; } }
    @media (min-width: 960px) and (min-width: 960px) {
      .main-nav li {
        margin-left: 3rem; } }
  @media (min-width: 960px) {
      .main-nav a {
        font-size: 14px;
        font-size: 1.4rem;
        color: #fff; } }
    @media (min-width: 960px) and (min-width: 1200px) {
      .main-nav a {
        font-size: 18px;
        font-size: 1.8rem; } }
  @media (min-width: 1200px) {
    .main-nav {
      margin-right: 0; } }

footer {
  background: url(../images/mobile-footer-bg.jpg) bottom center no-repeat;
  background-size: cover;
  padding-top: 35px;
  padding-top: 3.5rem; }
  @media (min-width: 680px) {
    footer {
      padding-top: 70px;
      padding-top: 7rem; } }
  @media (min-width: 960px) {
    footer {
      background: url(../images/footer-bg.jpg) bottom center no-repeat;
      background-size: auto; } }
  @media (min-width: 1600px) {
    footer {
      background-size: cover; } }
  footer .outer {
    background: rgba(12, 25, 66, 0.75); }

.mailing-list {
  padding: 0 10px;
  margin-bottom: 75px;
  margin-bottom: 7.5rem; }
  @media (min-width: 680px) {
    .mailing-list {
      padding: 0 20px;
      overflow: hidden;
      margin-bottom: 60px;
      margin-bottom: 6rem; } }
  @media (min-width: 960px) {
    .mailing-list {
      margin-bottom: 100px;
      margin-bottom: 10rem; } }
  @media (min-width: 1200px) {
    .mailing-list {
      max-width: 1200px;
      margin: 0 auto 100px;
      margin: 0 auto 10rem;
      padding: 0; } }
  .mailing-list form {
    margin: 0; }
  .mailing-list .form-label {
    height: 5rem;
    padding: 0 10px;
    background-color: #1C1E41;
    line-height: 5rem;
    color: #fff;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    font-size: 18px;
    font-size: 1.8rem; }
    @media (min-width: 680px) {
      .mailing-list .form-label {
        float: left;
        width: 50%; } }
  .mailing-list .form-field {
    float: left;
    margin-left: -50px;
    width: 100%; }
    @media (min-width: 680px) {
      .mailing-list .form-field {
        width: 44%;
        margin-left: 0; } }
    .mailing-list .form-field input {
      font-size: 18px;
      font-size: 1.8rem;
      margin-left: 50px;
      width: 100%;
      padding-right: 50px;
      padding-left: 10px;
      height: 50px;
      height: 5rem;
      border: none; }
      @media (min-width: 680px) {
        .mailing-list .form-field input {
          margin-left: 0; } }
  .mailing-list .submit-area {
    float: right;
    width: 50px;
    width: 5rem;
    z-index: 10;
    position: relative; }
    @media (min-width: 680px) {
      .mailing-list .submit-area {
        width: 6%; } }
    .mailing-list .submit-area input {
      background-image: url("../images/right-arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      height: 50px;
      height: 5rem; }
      .ie-9 .mailing-list .submit-area input {
        background-image: url("../images/right-arrow.png"); }

.footer-logo {
  text-align: center;
  margin-bottom: 35px;
  margin-bottom: 3.5rem;
  padding-top: 45px;
  padding-top: 4.5rem; }
  @media (min-width: 680px) {
    .footer-logo {
      padding-top: 20px;
      padding-top: 2rem;
      text-align: left;
      float: left;
      margin-right: 35px;
      margin-right: 3.5rem; } }
  @media (min-width: 960px) {
    .footer-logo {
      padding-top: 60px;
      padding-top: 6rem;
      margin-bottom: 60px;
      margin-bottom: 6rem; }
      .footer-logo img {
        width: 200px;
        height: auto; } }

.footer-nav {
  display: none; }
  @media (min-width: 680px) {
    .footer-nav {
      display: block;
      float: right;
      padding-top: 20px;
      padding-top: 2rem;
      width: 65%; }
      .footer-nav ul {
        list-style: none;
        padding-right: 30px;
        padding-right: 3rem; }
      .footer-nav li {
        font-size: 12px;
        font-size: 1.2rem;
        color: #fff;
        float: left;
        width: 25%;
        margin-bottom: 15px;
        margin-bottom: 1.5rem; }
        .footer-nav li:nth-child(3n) {
          width: 50%; }
      .footer-nav a {
        font-size: 12px;
        font-size: 1.2rem;
        color: #fff; } }
  @media (min-width: 960px) {
    .footer-nav {
      padding-top: 60px;
      padding-top: 6rem;
      float: left;
      width: 43%; }
      .footer-nav ul {
        padding-right: 0; } }

.footer-partners {
  width: 70%;
  margin: 0 auto 3.5rem; }
  @media (min-width: 680px) {
    .footer-partners {
      clear: both;
      width: 25%;
      float: right;
      margin-bottom: 2rem; } }
  .footer-partners ul {
    list-style: none;
    overflow: hidden; }
  .footer-partners li {
    float: left;
    width: 30%; }
    .footer-partners li:nth-child(2n) {
      margin: 0 5%; }
    .footer-partners li img {
      width: 100%;
      height: auto; }
  @media (min-width: 960px) {
    .footer-partners {
      clear: none;
      padding-top: 5rem;
      padding-right: 2rem; } }

.mobile-copyright {
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
  margin-bottom: 3rem; }
  @media (min-width: 680px) {
    .mobile-copyright {
      display: none; } }

.footer-social {
  display: none; }
  @media (min-width: 680px) {
    .footer-social {
      display: block; }
      .footer-social ul {
        list-style: none; }
      .footer-social li {
        float: left;
        margin-right: 20px;
        margin-right: 2rem; }
        .footer-social li.connect-label {
          font-size: 24px;
          font-size: 2.4rem;
          line-height: 30px;
          line-height: 3rem;
          color: #fff; }
      .footer-social a {
        display: block; }
        .footer-social a svg {
          width: 30px;
          width: 3rem;
          height: 30px;
          height: 3rem; } }
  @media (min-width: 960px) {
    .footer-social {
      clear: both;
      padding-bottom: 40px;
      padding-bottom: 4rem;
      overflow: hidden; } }

.pg-footer {
  background-color: #312224; }

.pg-footer-header h3 {
  margin: 0 0 2rem;
  text-transform: uppercase;
  color: #fff;
  line-height: 1.2;
  font-size: 32px;
  font-size: 3.2rem; }

.vivid-address {
  padding: 0 0 3rem; }
  @media (min-width: 960px) {
    .vivid-address {
      float: right; }
      .vivid-address:after {
        content: "";
        display: table;
        clear: both; } }

.address-col {
  color: #fff; }
  @media (min-width: 680px) {
    .address-col {
      width: 100%;
      float: left; } }

.address-block {
  margin: 0 0 2rem;
  border-bottom: 1px solid #fff; }
  .address-block:after {
    content: "";
    display: table;
    clear: both; }
  .address-block h4 {
    margin: 0 0 1rem;
    text-transform: uppercase;
    color: #fff;
    line-height: 1.2;
    font-size: 24px;
    font-size: 2.4rem; }
  .address-block ul {
    list-style: none;
    margin: 0 0 2rem;
    font-size: 14px;
    font-size: 1.4rem; }
  .address-block li {
    line-height: 1.5; }
    .address-block li a {
      color: inherit;
      text-decoration: none; }
      @media (min-width: 960px) {
        .address-block li a:hover {
          text-decoration: underline; } }

.pg-footer-tel {
  font-size: 24px;
  font-size: 2.4rem; }

.map-btn {
  display: block;
  height: 3rem;
  padding: 0 0 0 1rem;
  margin: 0 0 2rem;
  float: left;
  background-color: #f33633;
  line-height: 3rem;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  font-size: 1.4rem; }
  .map-btn svg {
    fill: #fff;
    display: block;
    width: 3rem;
    height: 3rem;
    background-color: transparent;
    float: right; }
  @media (min-width: 960px) {
    .map-btn:hover {
      background-color: shade(#f33633, 5%); } }

.post-footer {
  padding: 3rem 0;
  background-color: #f33633; }
  .post-footer.contact-footer {
    border-top: 10px solid #312224; }

.vivid-credit {
  font-size: 14px;
  font-size: 1.4rem;
  text-align: right;
  padding: 1rem;
  color: #fff; }
  .vivid-credit a {
    color: #fff;
    font-weight: bold; }

/* ====================

IN-PAGE FORMS

==================== */
.submit-area {
  background: #E83E31; }
  .submit-area input {
    font-size: 18px;
    font-size: 1.8rem;
    color: #fff;
    border: none;
    background: none;
    display: block;
    width: 100%;
    text-align: left;
    padding: 0; }

.cf7 p {
  display: block;
  color: #1C1E41;
  font-size: 18px;
  font-size: 1.8rem; }

.cf7 span {
  font-size: 14px;
  font-size: 1.4rem;
  display: block; }

.cf7 input[type=text], .cf7 input[type=email], .cf7 input[type=tel] {
  height: 4rem; }
  .cf7 input[type=text]::-webkit-input-placeholder, .cf7 input[type=email]::-webkit-input-placeholder, .cf7 input[type=tel]::-webkit-input-placeholder {
    color: #312224; }
  .cf7 input[type=text]:-moz-placeholder, .cf7 input[type=email]:-moz-placeholder, .cf7 input[type=tel]:-moz-placeholder {
    /* Firefox 18- */
    color: #312224; }
  .cf7 input[type=text]::-moz-placeholder, .cf7 input[type=email]::-moz-placeholder, .cf7 input[type=tel]::-moz-placeholder {
    /* Firefox 19+ */
    color: #312224; }
  .cf7 input[type=text]:-ms-input-placeholder, .cf7 input[type=email]:-ms-input-placeholder, .cf7 input[type=tel]:-ms-input-placeholder {
    color: #312224; }
  .cf7 input[type=text] .placeholder, .cf7 input[type=email] .placeholder, .cf7 input[type=tel] .placeholder {
    color: #312224; }
  .cf7 input[type=text].wpcf7-not-valid, .cf7 input[type=email].wpcf7-not-valid, .cf7 input[type=tel].wpcf7-not-valid {
    border-color: #f33633;
    background-color: #fde1e0; }

.cf7 input[type=submit] {
  height: 4rem;
  padding: 0 1rem;
  border: none;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 1.4rem; }
  @media (min-width: 960px) {
    .cf7 input[type=submit] {
      background-size: auto 80%; } }

.cf7 textarea {
  padding: 1rem;
  height: 10rem; }
  .cf7 textarea::-webkit-input-placeholder {
    color: #312224; }
  .cf7 textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #312224; }
  .cf7 textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #312224; }
  .cf7 textarea:-ms-input-placeholder {
    color: #312224; }
  .cf7 textarea .placeholder {
    color: #312224; }
  @media (min-width: 680px) {
    .cf7 textarea {
      height: 15rem; } }

.cf7 .wpcf7-form-control-wrap {
  position: relative; }

.cf7 .wpcf7-not-valid-tip {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: block;
  height: 2rem;
  margin: 0;
  padding-left: 2.5rem;
  background-image: url("../images/icon-warning.svg");
  background-repeat: no-repeat; }
  .ie-9 .cf7 .wpcf7-not-valid-tip {
    background-image: url("../images/icon-warning.png"); }

.cf7 .wpcf7-response-output {
  height: 4rem;
  float: left;
  line-height: 4rem;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem; }
  @media (min-width: 1200px) {
    .cf7 .wpcf7-response-output {
      height: 5rem;
      line-height: 5rem; } }

.cf7 .wpcf7-validation-errors {
  padding: 0 0 0 4rem;
  color: #fff;
  background-position: left center;
  background-image: url("../images/icon-warning.svg");
  background-repeat: no-repeat; }
  .ie-9 .cf7 .wpcf7-validation-errors {
    background-image: url("../images/icon-warning.png"); }

.mailing-list .wpcf7-not-valid-tip {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: block;
  height: 2rem;
  margin: 0;
  padding-left: 2.5rem;
  background-image: url("../images/icon-warning.svg");
  background-repeat: no-repeat; }
  .ie-9 .mailing-list .wpcf7-not-valid-tip {
    background-image: url("../images/icon-warning.png"); }

.quick-contact:after {
  content: "";
  display: table;
  clear: both; }

@media (min-width: 680px) {
  .quick-contact {
    margin: 0 0 3rem; }
    .quick-contact:after {
      content: "";
      display: table;
      clear: both; } }

@media (min-width: 960px) {
  .quick-contact {
    width: 50%;
    float: left;
    margin: 0; } }

@media (min-width: 1200px) {
  .quick-contact {
    width: 61.666%; } }

@media (min-width: 1600px) {
  .quick-contact {
    width: 50%; } }

.quick-contact-form p {
  margin: 0 0 2rem;
  color: #fff; }

@media (min-width: 680px) {
  .quick-contact-form input[type=submit] {
    width: 50%;
    float: right; } }

@media (min-width: 960px) {
  .quick-contact-form input[type=submit]:hover {
    background-color: shade(#f33633, 5%);
    content: ""; } }

.quick-contact .wpcf7-response-output {
  width: 100%;
  clear: both; }

.quick-contact-fields:after {
  content: "";
  display: table;
  clear: both; }

@media (min-width: 680px) {
  .quick-contact-column {
    width: 49%;
    float: left; }
    .quick-contact-column:last-child {
      float: right; } }

@media (min-width: 680px) {
  .quick-contact-enquiry {
    clear: both; } }

@media (min-width: 680px) {
  .quick-contact-submit {
    width: 47.5%;
    float: right; } }

.contact-us-form p {
  margin: 0 0 2rem; }
  @media (min-width: 960px) {
    .contact-us-form p {
      margin: 0 0 4rem; } }

.contact-us-form span {
  margin: 1rem 0 0; }

@media (min-width: 960px) {
  .contact-us-form input[type=submit]:hover {
    background-color: #312224; } }

@media (min-width: 680px) {
  .contact-us-submit {
    width: 47.5%;
    float: left; } }

.screen-reader-response {
  display: none; }

img.ajax-loader {
  display: none !important; }

.apply-form {
  position: relative;
  width: 100%;
  padding: 3rem;
  background-color: #fff; }
  .apply-form:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 960px) {
    .apply-form {
      width: 75%;
      margin: 0 auto; } }
  .apply-form h2 {
    margin: 0 0 2rem;
    text-transform: uppercase;
    color: #f33633;
    line-height: 1.2;
    font-size: 24px;
    font-size: 2.4rem; }
  .apply-form .wpcf7-validation-errors {
    color: #312224; }

.apply-form-row {
  padding: 0 0 1rem; }
  .apply-form-row p {
    margin: 0 0 1rem; }

@media (min-width: 568px) {
  .apply-submit-row input[type=submit] {
    width: 50%;
    float: right; } }

.mailing-list span.wpcf7-not-valid-tip {
  margin: 1rem 0 2em 0;
  font-size: 14px;
  font-size: 1.4rem; }

.mailing-list .wpcf7-response-output {
  clear: both;
  font-size: 18px;
  font-size: 1.8rem;
  color: #ED1C24;
  border-color: #ED1C24;
  background: #fff;
  margin: 0; }

a:hover {
  text-decoration: underline; }

.carousel {
  height: 34rem;
  background-size: cover;
  position: relative;
  margin-bottom: 2rem; }
  @media (min-width: 680px) {
    .carousel {
      height: 40rem;
      margin: 0 2rem 4rem; } }
  @media (min-width: 1200px) {
    .carousel {
      max-width: 1200px;
      margin: 0 auto 4rem; } }
  .carousel .slide {
    height: 40rem;
    position: relative;
    background-size: cover; }
  .carousel blockquote {
    font-size: 24px;
    font-size: 2.4rem;
    font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif;
    background: #1C1E41;
    color: #fff;
    padding: 2.5rem;
    position: absolute;
    bottom: 0;
    width: 100%; }
    @media (min-width: 680px) {
      .carousel blockquote {
        font-size: 32px;
        font-size: 3.2rem;
        background: rgba(28, 30, 65, 0.85);
        width: auto;
        max-width: 470px;
        bottom: auto;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%); } }

@media (min-width: 680px) {
  .home .carousel {
    margin-bottom: 3rem; } }

.header-image {
  height: 34rem;
  background-size: cover;
  position: relative;
  margin-bottom: 2rem; }
  @media (min-width: 680px) {
    .header-image {
      height: 40rem;
      margin: 0 2rem 4rem; } }
  @media (min-width: 1200px) {
    .header-image {
      margin: 0 auto 4rem; } }

.breadcrumb {
  margin-bottom: 4rem; }
  .breadcrumb a, .breadcrumb span {
    font-size: 14px;
    font-size: 1.4rem;
    color: #405261; }

.page-heading h1 {
  font-size: 24px;
  font-size: 2.4rem;
  font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
  color: #1C1E41;
  margin-bottom: 2rem; }
  @media (min-width: 680px) {
    .page-heading h1 {
      font-size: 48px;
      font-size: 4.8rem; } }

.page-heading.page-heading-center {
  text-align: center; }

.hire-guide {
  margin-bottom: 2rem; }
  @media (min-width: 680px) {
    .hire-guide {
      margin-bottom: 3rem; } }
  .hire-guide form {
    margin: 0; }
  .hire-guide ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    .hire-guide ul li {
      background: url("../images/down-arrow.svg") no-repeat 95% center #EFEFEF;
      overflow: hidden;
      padding: 1.5rem 1rem; }
      @media (min-width: 680px) {
        .hire-guide ul li {
          float: left;
          width: 30%;
          height: 5.5rem; } }
  .hire-guide .hire-label {
    font-size: 18px;
    font-size: 1.8rem;
    background: #1C1E41;
    color: #fff;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif; }
    @media (min-width: 680px) {
      .hire-guide .hire-label {
        width: 20%; } }
  .hire-guide .select-activity {
    border-bottom: 1px solid #9D9D9C; }
    @media (min-width: 680px) {
      .hire-guide .select-activity {
        border-bottom: none;
        border-right: 2px solid #9D9D9C; } }
  .hire-guide select {
    font-size: 18px;
    font-size: 1.8rem;
    color: #9D9D9C;
    border: none;
    background: none;
    width: 110%;
    font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif; }
    @media (min-width: 680px) {
      .hire-guide select {
        width: 120%; } }
    .hire-guide select option {
      padding: 0; }
  .hire-guide .submit-area {
    background: #E83E31; }
    .hire-guide .submit-area input {
      background-image: url("../images/right-arrow.svg");
      background-repeat: no-repeat;
      background-position: 98% center;
      font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif; }
      .ie-9 .hire-guide .submit-area input {
        background-image: url("../images/right-arrow.png"); }
      @media (min-width: 680px) {
        .hire-guide .submit-area input {
          padding-right: 3rem;
          text-align: center;
          background-position: 70% center; } }
    @media (min-width: 680px) {
      .hire-guide .submit-area {
        width: 20%; } }

.inner.quicklinks {
  margin-bottom: 2rem; }
  @media (min-width: 680px) {
    .inner.quicklinks {
      margin-bottom: 3rem; } }
  .inner.quicklinks a {
    font-size: 24px;
    font-size: 2.4rem;
    display: block;
    color: #fff;
    background-size: cover;
    height: 30rem;
    position: relative; }
    .inner.quicklinks a::after {
      content: "";
      background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
      /* IE6-9 */
      opacity: 0.25;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute; }
    @media (min-width: 680px) {
      .inner.quicklinks a {
        height: 23rem;
        width: 33.33%;
        float: left; } }
    @media (min-width: 960px) {
      .inner.quicklinks a {
        height: 40rem; } }
  .inner.quicklinks div {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    width: 50%;
    margin: 0 auto;
    z-index: 10;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif; }

.news-wrapper .news-label {
  font-size: 24px;
  font-size: 2.4rem;
  background-image: url("../images/right-arrow.svg");
  background-repeat: no-repeat;
  font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
  background-position: 97% center;
  background-color: #1C1E41;
  background-size: 5px 9px; }
  .ie-9 .news-wrapper .news-label {
    background-image: url("../images/right-arrow.png"); }
  @media (min-width: 680px) {
    .news-wrapper .news-label {
      font-size: 18px;
      font-size: 1.8rem;
      float: left;
      background-position: 90% center; } }
  @media (min-width: 960px) {
    .news-wrapper .news-label {
      font-size: 32px;
      font-size: 3.2rem; } }
  .news-wrapper .news-label a {
    display: block;
    color: #fff;
    padding: 1.5rem 1rem; }
    @media (min-width: 680px) {
      .news-wrapper .news-label a {
        padding-right: 7rem; } }
    @media (min-width: 960px) {
      .news-wrapper .news-label a {
        padding: 2rem 7rem 2rem 2rem; } }
  .news-wrapper .news-label span {
    display: none;
    color: #fff; }
    @media (min-width: 680px) {
      .news-wrapper .news-label span {
        display: block; } }

.news-wrapper .news-panel {
  clear: both;
  overflow: hidden;
  background-color: #EFEFEF;
  position: relative; }

.news-social {
  display: none; }
  @media (min-width: 680px) {
    .news-social {
      display: block;
      height: 100%;
      position: absolute;
      right: 0;
      width: 8%; }
      .news-social ul {
        list-style: none;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); }
      .news-social li {
        margin-bottom: 1rem; }
        .news-social li:last-child {
          margin-bottom: 0; }
      .news-social a {
        display: block;
        text-align: center; }
        .news-social a svg {
          width: 3rem;
          height: 3rem; } }
  @media (min-width: 960px) {
    .news-social {
      width: 5%; } }

.news-items-wrapper {
  display: none; }
  @media (min-width: 680px) {
    .news-items-wrapper {
      display: block;
      float: left;
      width: 92%; }
      .news-items-wrapper .news-item {
        background-color: #e2e2e2;
        padding: 3rem 13rem 3rem 2.5rem;
        min-height: 16rem; }
        .news-items-wrapper .news-item:first-child {
          border-bottom: 1px solid #fff; }
        .news-items-wrapper .news-item:nth-child(n+3) {
          display: none; }
        .news-items-wrapper .news-item .news-image {
          float: left;
          width: 10rem;
          height: 10rem; }
          .news-items-wrapper .news-item .news-image img {
            width: 100%;
            height: auto; }
        .news-items-wrapper .news-item .news-info {
          margin-left: 11rem; }
          .news-items-wrapper .news-item .news-info a {
            padding: 0.6rem 1rem; }
        .news-items-wrapper .news-item .news-text {
          font-size: 18px;
          font-size: 1.8rem;
          color: #1C1E41;
          margin-bottom: .5rem;
          height: 4rem;
          overflow: hidden; }
        .news-items-wrapper .news-item .news-excerpt {
          font-size: 12px;
          font-size: 1.2rem;
          margin-bottom: 1rem; }
        .news-items-wrapper .news-item .read-more {
          margin-top: .5rem; } }
  @media (min-width: 960px) {
    .news-items-wrapper {
      width: 95%; }
      .news-items-wrapper .news-item {
        float: left;
        width: 50%;
        padding: 4rem 2.5rem; }
        .news-items-wrapper .news-item:nth-child(odd) {
          border-right: 1px solid #fff; }
        .news-items-wrapper .news-item:nth-child(-n+2) {
          border-bottom: 1px solid #fff; }
        .news-items-wrapper .news-item:nth-child(n+3) {
          display: block; }
        .news-items-wrapper .news-item .news-image {
          width: 11rem; }
          .news-items-wrapper .news-item .news-image img {
            width: 100%;
            height: auto; }
        .news-items-wrapper .news-item .news-info {
          margin-left: 14rem; }
          .news-items-wrapper .news-item .news-info a {
            font-size: 14px;
            font-size: 1.4rem; }
        .news-items-wrapper .news-item .news-text {
          margin-bottom: 1rem; } }

.partners h2 {
  font-size: 18px;
  font-size: 1.8rem;
  color: #1C1E41;
  font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
  padding: 3.5rem 0 2.5rem;
  text-align: center; }
  @media (min-width: 680px) {
    .partners h2 {
      font-size: 24px;
      font-size: 2.4rem;
      padding: 5rem 0 3.5rem; } }
  @media (min-width: 960px) {
    .partners h2 {
      font-size: 32px;
      font-size: 3.2rem;
      padding: 5rem 0; } }

.partners ul {
  list-style: none;
  width: 70%;
  margin: 0 auto;
  text-align: center; }

.partners li {
  display: inline-block;
  width: 49%;
  text-align: center; }
  .partners li a {
    display: block;
    text-decoration: none; }
  .partners li img {
    width: 75%;
    height: auto; }
    @media (min-width: 680px) {
      .partners li img {
        width: 65%; } }
    @media (min-width: 960px) {
      .partners li img {
        width: 50%; } }

.isotope-item {
  position: relative;
  width: 100%;
  padding-top: 1rem;
  margin-bottom: 2rem;
  float: left;
  margin-right: 1%; }
  .isotope-item.twitter {
    background: #47b0e3;
    padding-bottom: 7rem; }
    @media (min-width: 680px) {
      .isotope-item.twitter {
        width: 49%; } }
    @media (min-width: 1200px) {
      .isotope-item.twitter {
        width: 24%; } }
    .isotope-item.twitter .social-icon {
      border-bottom: 3px solid #1f91c9; }
    .isotope-item.twitter .item-date {
      position: absolute;
      bottom: 2.5rem; }
  .isotope-item.facebook {
    background: #44589f;
    padding-bottom: 7rem;
    max-height: 50rem;
    overflow: hidden; }
    @media (min-width: 680px) {
      .isotope-item.facebook {
        width: 49%; } }
    @media (min-width: 1200px) {
      .isotope-item.facebook {
        width: 24%; } }
    .isotope-item.facebook .social-icon {
      border-bottom: 3px solid #303e70; }
    .isotope-item.facebook .item-date {
      position: absolute;
      bottom: 2.5rem; }
  .isotope-item.instagram {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 30rem; }
    @media (min-width: 680px) {
      .isotope-item.instagram {
        height: 60rem;
        width: 49%; } }
    .isotope-item.instagram .social-icon {
      border-bottom: 3px solid #1f91c9;
      width: 6rem; }
      .isotope-item.instagram .social-icon svg {
        width: 6rem;
        height: 6rem; }
  .isotope-item.post {
    border: 1px solid #d5d5d5;
    height: auto;
    padding-bottom: 3.5rem; }
    .isotope-item.post .social-icon {
      border-bottom: 3px solid #d5d5d5;
      width: 4rem; }
      .isotope-item.post .social-icon svg {
        width: 4rem;
        height: 4rem; }
    .isotope-item.post h2 {
      font-size: 24px;
      font-size: 2.4rem;
      color: #1C1E41;
      margin-bottom: 3rem;
      padding: 0 2rem; }
    .isotope-item.post .item-text {
      color: #706F6F;
      margin-bottom: 3.5rem;
      max-height: 35rem;
      overflow: hidden; }
    .isotope-item.post .item-date {
      font-size: 14px;
      font-size: 1.4rem;
      color: #1C1E41; }
  .isotope-item.vimeo {
    background-size: cover;
    height: 30rem; }
    @media (min-width: 680px) {
      .isotope-item.vimeo {
        height: 36rem;
        width: 49%; } }
    .isotope-item.vimeo .social-icon {
      border-bottom: 3px solid #1f91c9;
      width: 6rem; }
      .isotope-item.vimeo .social-icon svg {
        width: 6rem;
        height: 6rem; }
    .isotope-item.vimeo a {
      display: block;
      height: 60rem;
      margin-top: -1rem; }
  .isotope-item .social-icon {
    margin-bottom: 3.5rem;
    width: 4rem; }
    .isotope-item .social-icon svg {
      width: 4rem;
      height: 4rem; }
  .isotope-item .item-text, .isotope-item .item-date {
    font-size: 18px;
    font-size: 1.8rem;
    padding: 0 2rem;
    color: #fff;
    line-height: 1.5;
    word-wrap: break-word; }
    .isotope-item .item-text a, .isotope-item .item-date a {
      color: #fff; }
  .isotope-item .item-date {
    font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif; }

.gutter-sizer {
  width: 1%; }

.activities:after {
  content: "";
  display: table;
  clear: both; }

.activities .activity {
  display: block;
  height: 39rem;
  margin-bottom: 3rem;
  background-size: cover;
  background-position: center;
  position: relative;
  text-decoration: none; }
  .activities .activity::after {
    content: "";
    background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
    /* IE6-9 */
    opacity: 0.25;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute; }
  @media (min-width: 680px) {
    .activities .activity {
      float: left;
      width: 49%;
      margin-right: 2%; }
      .activities .activity:nth-child(2n) {
        margin-right: 0; } }
  @media (min-width: 1200px) {
    .activities .activity {
      float: left;
      width: 32%; }
      .activities .activity:nth-child(2n) {
        margin-right: 2%; }
      .activities .activity:nth-child(3n) {
        margin-right: 0; } }
  .activities .activity h2 {
    font-size: 32px;
    font-size: 3.2rem;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    color: #fff;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    z-index: 10; }
  .activities .activity span {
    background-image: url("../images/right-arrow.svg");
    background-repeat: no-repeat;
    background-color: #E83E31;
    background-position: center;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 6rem;
    height: 6rem;
    z-index: 10; }
    .ie-9 .activities .activity span {
      background-image: url("../images/right-arrow.png"); }

.section-header {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  margin-bottom: 4rem; }
  @media (min-width: 680px) {
    .section-header {
      min-height: 40rem;
      margin: 0 2rem 4rem; } }
  @media (min-width: 1200px) {
    .section-header {
      margin: 0 auto 4rem; } }
  .section-header img {
    width: 100%;
    height: auto; }
    @media (min-width: 680px) {
      .section-header img {
        display: none; } }
  .section-header .overlay {
    font-size: 18px;
    font-size: 1.8rem;
    background: #1C1E41;
    font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif;
    color: #fff;
    text-align: center;
    padding: 3rem 2rem; }
    @media (min-width: 680px) {
      .section-header .overlay {
        font-size: 24px;
        font-size: 2.4rem;
        padding: 3rem;
        background: rgba(28, 30, 65, 0.65);
        text-align: left;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        width: 100%; } }
    @media (min-width: 1200px) {
      .section-header .overlay {
        width: 50%; } }
  .section-header .quote-ref {
    font-size: 14px;
    font-size: 1.4rem;
    font-family: "Foundry Form Sans W01 Bk", Arial, sans-serif;
    margin-top: 2rem; }

.content-wrapper {
  margin-bottom: 4rem; }
  @media (min-width: 680px) {
    .content-wrapper {
      margin-bottom: 6rem; } }
  @media (min-width: 1200px) {
    .content-wrapper {
      margin-bottom: 10rem; } }

.main-col {
  font-size: 16px;
  font-size: 1.6rem;
  color: #706F6F; }
  @media (min-width: 960px) {
    .main-col {
      float: left;
      width: 74%; } }
  .main-col p, .main-col ul {
    margin-bottom: 2rem;
    line-height: 1.5; }
    .main-col p a, .main-col ul a {
      color: #1C1E41; }
      .main-col p a:hover, .main-col ul a:hover {
        text-decoration: underline; }
  .main-col iframe {
    width: 100%; }

.intro-text {
  font-size: 18px;
  font-size: 1.8rem;
  color: #1C1E41;
  font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif;
  margin-bottom: 4rem; }
  @media (min-width: 680px) {
    .intro-text {
      font-size: 24px;
      font-size: 2.4rem; } }
  @media (min-width: 1200px) {
    .intro-text {
      margin-bottom: 6rem; } }

.what-to-expect {
  border: 1px solid #e2e2e2;
  padding: 3rem 1.5rem; }
  @media (min-width: 680px) {
    .what-to-expect {
      padding: 3rem; } }
  .what-to-expect h2 {
    font-size: 24px;
    font-size: 2.4rem;
    color: #1C1E41;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    text-align: center;
    margin-bottom: 2rem; }
    @media (min-width: 680px) {
      .what-to-expect h2 {
        margin-bottom: 3rem;
        text-align: left; } }
  .what-to-expect ul {
    list-style: none; }
  .what-to-expect li {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 3rem;
    text-align: center; }
    @media (min-width: 680px) {
      .what-to-expect li {
        font-size: 18px;
        font-size: 1.8rem;
        text-align: left; }
        .what-to-expect li:after {
          content: "";
          display: table;
          clear: both; } }
    .what-to-expect li:last-child {
      margin-bottom: 0; }
    .what-to-expect li span {
      display: block;
      margin: 0 auto 1.5rem;
      width: 2rem;
      height: 2rem; }
      @media (min-width: 680px) {
        .what-to-expect li span {
          float: left;
          width: 4rem;
          height: 4rem;
          margin-right: 2rem; } }
    @media (min-width: 680px) {
      .what-to-expect li .wte_text {
        margin-left: 6rem; } }

.find-an-activity-guide h2 {
  display: none; }
  @media (min-width: 680px) {
    .find-an-activity-guide h2 {
      font-size: 32px;
      font-size: 3.2rem;
      font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
      display: block;
      color: #1C1E41;
      text-align: center;
      margin-bottom: 40px;
      margin-bottom: 4rem; } }
  @media (min-width: 960px) {
    .find-an-activity-guide h2 {
      margin-bottom: 50px;
      margin-bottom: 5rem; } }

.find-an-activity-guide ul {
  list-style: none; }

@media (min-width: 680px) {
  .find-an-activity-guide li {
    float: left;
    width: 49%;
    margin-right: 2%; }
    .find-an-activity-guide li:nth-child(2n) {
      margin-right: 0; } }

@media (min-width: 960px) {
  .find-an-activity-guide li {
    float: left;
    width: 32%;
    margin-right: 2%; }
    .find-an-activity-guide li:nth-child(2n) {
      margin-right: 2%; }
    .find-an-activity-guide li:nth-child(3n) {
      margin-right: 0; } }

.find-an-activity-guide a {
  height: 300px;
  height: 30rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: block;
  margin-bottom: 20px;
  margin-bottom: 2rem; }
  .find-an-activity-guide a::after {
    content: "";
    background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
    /* IE6-9 */
    opacity: 0.25;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute; }

.find-an-activity-guide h3 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  font-size: 24px;
  font-size: 2.4rem;
  font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  z-index: 10; }

.find-an-activity-guide span {
  background-image: url("../images/right-arrow.svg");
  background-repeat: no-repeat;
  background-color: #E83E31;
  background-position: center;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 5rem;
  height: 5rem; }
  .ie-9 .find-an-activity-guide span {
    background-image: url("../images/right-arrow.png"); }
  @media (min-width: 680px) {
    .find-an-activity-guide span {
      width: 6rem;
      height: 6rem; } }

.sidebar-col {
  display: none; }
  @media (min-width: 960px) {
    .sidebar-col {
      display: block;
      width: 22%;
      float: right; } }

.find-a-guide-intro {
  min-height: 63.5rem;
  margin-bottom: 8rem; }
  @media (min-width: 960px) {
    .find-a-guide-intro {
      min-height: 55.5rem; } }
  .find-a-guide-intro h1 {
    font-size: 48px;
    font-size: 4.8rem;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    color: #fff;
    margin-bottom: 4rem; }
    @media (min-width: 960px) {
      .find-a-guide-intro h1 {
        font-size: 56px;
        font-size: 5.6rem;
        width: 45%; } }
  .find-a-guide-intro p {
    font-size: 18px;
    font-size: 1.8rem;
    color: #fff;
    width: 90%;
    line-height: 1.2;
    margin-bottom: 2.5rem; }
    @media (min-width: 960px) {
      .find-a-guide-intro p {
        width: 45%; } }

.find-a-guide-activity {
  margin-bottom: 50px;
  margin-bottom: 5rem; }
  .find-a-guide-activity:after {
    content: "";
    display: table;
    clear: both; }
  .find-a-guide-activity h2 {
    font-size: 24px;
    font-size: 2.4rem;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    text-align: center;
    color: #1C1E41;
    margin-bottom: 50px;
    margin-bottom: 5rem; }
  .find-a-guide-activity ul {
    list-style: none; }
  .find-a-guide-activity li {
    position: relative;
    float: left;
    text-align: center;
    margin-bottom: 50px;
    margin-bottom: 5rem;
    width: 48%; }
    @media (min-width: 680px) {
      .find-a-guide-activity li {
        width: 32%; } }
    @media (min-width: 960px) {
      .find-a-guide-activity li {
        width: 20%; } }
  .find-a-guide-activity label span.filter-option {
    background: url("../images/activity-deselected.png") center no-repeat;
    background-size: 100% auto;
    display: block;
    max-width: 20rem;
    height: 20rem;
    position: relative;
    z-index: 10;
    cursor: pointer;
    margin: 0 auto; }
  .find-a-guide-activity label input {
    position: absolute;
    top: 50%;
    left: 50%; }
  .find-a-guide-activity label input[type="checkbox"]:checked + span.filter-option {
    background: url("../images/activity-selected.png") center no-repeat; }
    .find-a-guide-activity label input[type="checkbox"]:checked + span.filter-option span {
      color: #fff; }
  .find-a-guide-activity label .filter-option span {
    font-size: 18px;
    font-size: 1.8rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    text-align: center;
    color: #1C1E41;
    display: block;
    width: 80%;
    margin: 0 auto; }

.find-a-guide-location {
  margin-bottom: 120px;
  margin-bottom: 12rem; }
  .find-a-guide-location:after {
    content: "";
    display: table;
    clear: both; }
  .find-a-guide-location h2 {
    font-size: 24px;
    font-size: 2.4rem;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    text-align: center;
    color: #1C1E41;
    margin-bottom: 40px;
    margin-bottom: 4rem; }
  .find-a-guide-location .select-wrapper {
    background: url("../images/down-arrow.svg") no-repeat 98% center #EFEFEF;
    margin: 0 auto;
    max-width: 700px;
    max-width: 70em;
    overflow: hidden;
    cursor: pointer; }
    .find-a-guide-location .select-wrapper select {
      font-size: 18px;
      font-size: 1.8rem;
      color: #9D9D9C;
      background: none;
      border: none;
      padding: 10px;
      padding: 1rem;
      width: 110%; }

.find-guide-results h2 {
  font-size: 32px;
  font-size: 3.2rem;
  font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
  margin-bottom: 2rem; }
  @media (min-width: 960px) {
    .find-guide-results h2 {
      float: left;
      margin-bottom: 4rem; } }

@media (min-width: 960px) {
  .find-guide-results .guides-filter {
    float: right; } }

.find-guide-results .guides-filter .view-filter-label {
  font-size: 18px;
  font-size: 1.8rem;
  color: #1C1E41; }
  @media (min-width: 960px) {
    .find-guide-results .guides-filter .view-filter-label {
      margin-right: 5rem; } }

.find-guide-results .guides-filter .sort {
  float: right; }
  @media (min-width: 960px) {
    .find-guide-results .guides-filter .sort {
      float: none; } }

.find-guide-results .guides-filter .atoz {
  font-size: 18px;
  font-size: 1.8rem;
  padding: 0.5rem 1rem;
  margin-left: 1rem; }
  .find-guide-results .guides-filter .atoz:hover {
    background-color: #b82015; }

.find-guide-results ul {
  clear: both;
  list-style: none; }

@media (min-width: 680px) {
  .find-guide-results li {
    float: left;
    width: 49%;
    margin-right: 2%; }
    .find-guide-results li:nth-child(2n) {
      margin-right: 0; } }

@media (min-width: 1200px) {
  .find-guide-results li {
    float: left;
    width: 32%;
    margin-right: 2%; }
    .find-guide-results li:nth-child(2n) {
      margin-right: 2%; }
    .find-guide-results li:nth-child(3n) {
      margin-right: 0; } }

.find-guide-results li a {
  height: 300px;
  height: 30rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: block;
  margin-bottom: 20px;
  margin-bottom: 2rem; }
  .find-guide-results li a::after {
    content: "";
    background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
    /* IE6-9 */
    opacity: 0.25;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute; }
  .find-guide-results li a:hover {
    text-decoration: none; }
    .find-guide-results li a:hover span {
      background-color: #b82015; }

.find-guide-results li h3 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  font-size: 24px;
  font-size: 2.4rem;
  font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  z-index: 10; }

.find-guide-results li span {
  background-image: url("../images/right-arrow.svg");
  background-repeat: no-repeat;
  background-color: #E83E31;
  background-position: center;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 5rem;
  height: 5rem; }
  .ie-9 .find-guide-results li span {
    background-image: url("../images/right-arrow.png"); }
  @media (min-width: 680px) {
    .find-guide-results li span {
      width: 6rem;
      height: 6rem; } }

.main-nav-mob {
  position: absolute;
  top: 10rem;
  left: 0;
  width: 100%;
  z-index: 10040; }
  .main-nav-mob a {
    font-size: 18px;
    font-size: 1.8rem;
    color: #fff;
    display: block;
    background-color: #1C1E41;
    padding: 2rem; }

.guide {
  padding-top: 30px;
  padding-top: 3rem; }
  .guide .guide-image {
    width: 150px;
    width: 15em;
    height: 150px;
    height: 15em;
    overflow: hidden;
    border-radius: 75px;
    border-radius: 7.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto 30px;
    margin: 0 auto 3rem; }
  .guide h1, .guide h2 {
    font-size: 32px;
    font-size: 3.2rem;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    text-align: center;
    color: #1C1E41;
    margin-bottom: 20px;
    margin-bottom: 2rem; }
  .guide h2 {
    font-size: 24px;
    font-size: 2.4rem; }
  .guide .membership-type {
    font-size: 18px;
    font-size: 1.8rem;
    display: inline-block;
    color: #E83E31;
    vertical-align: top;
    margin-right: 2.5rem; }
  .guide .guide-activities {
    text-align: center;
    padding: 0 2rem; }
    @media (min-width: 1200px) {
      .guide .guide-activities {
        padding: 0; } }
    .guide .guide-activities ul {
      display: inline-block;
      list-style: none; }
    .guide .guide-activities li {
      font-size: 18px;
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: #E83E31;
      margin-bottom: 2.5rem; }
      @media (min-width: 680px) {
        .guide .guide-activities li {
          float: left;
          margin-right: 2.5rem; } }
    .guide .guide-activities span {
      width: 24px;
      width: 2.4rem;
      height: 24px;
      height: 2.4rem;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      margin-right: 0.5rem; }
  .guide .guide-bio-extract {
    font-size: 18px;
    font-size: 1.8rem;
    color: #706F6F;
    width: 100%;
    line-height: 1.5;
    max-width: 82rem;
    margin: 0 auto 2rem;
    text-align: center; }
    .guide .guide-bio-extract a {
      color: #000;
      text-decoration: underline; }
  .guide .social-links {
    text-align: center;
    margin-bottom: 7rem; }
    .guide .social-links ul {
      display: inline-block;
      list-style: none; }
    .guide .social-links li {
      float: left;
      width: 3rem;
      height: 3rem;
      margin-right: 2rem;
      overflow: hidden; }
  .guide .guide-bottom-activities {
    background: #EFEFEF;
    padding: 3rem 0; }
    .guide .guide-bottom-activities a {
      color: #706F6F;
      background: #fff;
      display: block;
      padding: 2rem 3.5rem;
      border-radius: 1rem; }
  .guide .guide-bio-outer {
    background: #1C1E41;
    margin-bottom: 8rem; }
    .guide .guide-bio-outer:after {
      content: "";
      display: table;
      clear: both; }
    @media (min-width: 960px) {
      .guide .guide-bio-outer .guide-bio-left, .guide .guide-bio-outer .guide-bio-right {
        float: left;
        width: 50%;
        overflow: hidden; } }
    .guide .guide-bio-outer .guide-bio {
      color: #fff;
      padding: 2rem 2rem 0 2rem; }
      @media (min-width: 960px) {
        .guide .guide-bio-outer .guide-bio {
          float: right;
          max-width: 600px;
          padding: 9rem 8rem 9rem 2rem; } }
      .guide .guide-bio-outer .guide-bio h3 {
        font-size: 32px;
        font-size: 3.2rem;
        font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
        margin-bottom: 2rem; }
        @media (min-width: 960px) {
          .guide .guide-bio-outer .guide-bio h3 {
            margin-bottom: 5rem; } }
      .guide .guide-bio-outer .guide-bio p {
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 1.5;
        margin-bottom: 2rem; }
    .guide .guide-bio-outer .guide-bio-image img {
      width: 100%; }
      @media (min-width: 960px) {
        .guide .guide-bio-outer .guide-bio-image img {
          width: auto; } }
  .guide .guide-contact {
    width: 90%;
    margin: 0 auto; }
    .guide .guide-contact:after {
      content: "";
      display: table;
      clear: both; }
    @media (min-width: 680px) {
      .guide .guide-contact {
        width: 62rem; } }
    .guide .guide-contact h2 {
      font-size: 32px;
      font-size: 3.2rem;
      margin-bottom: 6rem; }
    .guide .guide-contact address {
      font-size: 18px;
      font-size: 1.8rem;
      font-style: normal;
      line-height: 1.5;
      margin-bottom: 3rem; }
      @media (min-width: 680px) {
        .guide .guide-contact address {
          float: left;
          width: 26rem;
          margin-bottom: 0; } }
      .guide .guide-contact address span {
        float: left;
        width: 2.4rem;
        height: 2.4rem; }
      .guide .guide-contact address p {
        margin-left: 4.5rem; }
    .guide .guide-contact ul {
      list-style: none; }
      @media (min-width: 680px) {
        .guide .guide-contact ul {
          float: right;
          width: 28rem; } }
      .guide .guide-contact ul li {
        margin-bottom: 3rem;
        line-height: 2rem; }
        .guide .guide-contact ul li:after {
          content: "";
          display: table;
          clear: both; }
        .guide .guide-contact ul li a {
          font-size: 18px;
          font-size: 1.8rem;
          color: #000;
          margin-left: 4.5rem;
          display: block; }
      .guide .guide-contact ul span {
        float: left;
        width: 3rem;
        height: 3rem; }

@media (min-width: 680px) {
  .guide-location span {
    display: none; } }

.guide-location:hover {
  cursor: pointer; }
  @media (min-width: 680px) {
    .guide-location:hover span {
      display: block; } }

.become-guide-intro {
  position: relative;
  min-height: 840px;
  min-height: 84rem; }
  .become-guide-intro h1 {
    font-size: 56px;
    font-size: 5.6rem;
    color: #fff;
    margin-bottom: 4rem; }
  .become-guide-intro .become-guide-intro-content {
    font-size: 18px;
    font-size: 1.8rem;
    float: left;
    max-width: 540px;
    color: #fff;
    line-height: 1.5;
    margin-bottom: 2rem; }
    .become-guide-intro .become-guide-intro-content p {
      margin-bottom: 1rem; }
  .become-guide-intro .featured-video {
    clear: both; }
  .become-guide-intro .become-guide-apply {
    display: none; }
    @media (min-width: 960px) {
      .become-guide-intro .become-guide-apply {
        font-size: 18px;
        font-size: 1.8rem;
        color: #fff;
        position: absolute;
        bottom: 7rem;
        text-align: center;
        display: inline-block; }
        .become-guide-intro .become-guide-apply span {
          background-image: url("../images/right-arrow.svg");
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          width: 14px;
          height: 28px;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          margin: 1rem auto 0; }
          .ie-9 .become-guide-intro .become-guide-apply span {
            background-image: url("../images/right-arrow.png"); } }

.quotes-wrapper {
  font-size: 24px;
  font-size: 2.4rem;
  background: #1C1E41;
  font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif;
  padding: 8rem 0 5rem 0;
  color: #fff;
  text-align: center; }
  .quotes-wrapper .attribution {
    font-size: 16px;
    font-size: 1.6rem;
    font-family: "Foundry Form Sans W01 Bk", Arial, sans-serif;
    margin-top: 4rem; }

.how-to-apply {
  max-width: 1150px;
  margin: 0 auto;
  padding: 2rem 0; }
  @media (min-width: 680px) {
    .how-to-apply {
      padding: 8rem 0 5rem 0; } }
  .how-to-apply h2 {
    font-size: 48px;
    font-size: 4.8rem;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    text-align: center;
    margin-bottom: 4rem; }
    @media (min-width: 680px) {
      .how-to-apply h2 {
        margin-bottom: 7rem; } }
  .how-to-apply .steps {
    list-style: none; }
    @media (min-width: 680px) {
      .how-to-apply .steps {
        background: url("../images/activity-deselected.png") center 35% no-repeat;
        background-size: 175px; } }
    .how-to-apply .steps li {
      text-align: center;
      margin-bottom: 4rem; }
      @media (min-width: 680px) {
        .how-to-apply .steps li {
          width: 40%;
          margin-bottom: 8rem; } }
    .how-to-apply .steps .number {
      font-size: 32px;
      font-size: 3.2rem;
      display: block;
      text-align: center;
      color: #fff;
      margin: 0 auto 3rem;
      width: 5rem;
      height: 5rem;
      line-height: 5rem;
      border-radius: 2.5rem; }
      @media (min-width: 680px) {
        .how-to-apply .steps .number {
          font-size: 56px;
          font-size: 5.6rem;
          width: 10rem;
          height: 10rem;
          line-height: 10rem;
          border-radius: 5rem; } }
    .how-to-apply .steps h3 {
      font-size: 32px;
      font-size: 3.2rem;
      font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
      margin-bottom: 2rem; }
    .how-to-apply .steps .description {
      font-size: 18px;
      font-size: 1.8rem;
      color: #9D9D9C;
      padding: 0 2rem;
      margin-bottom: 1rem; }
      @media (min-width: 680px) {
        .how-to-apply .steps .description {
          margin-bottom: 6rem;
          padding: 0; } }
    .how-to-apply .steps .link a {
      font-size: 18px;
      font-size: 1.8rem;
      display: inline-block;
      padding: 0.5rem 1rem;
      color: #fff; }
  @media (min-width: 680px) {
    .how-to-apply .step-1 {
      float: left; } }
  .how-to-apply .step-1 .number, .how-to-apply .step-1 .link a {
    background-color: #E62F1C; }
  @media (min-width: 680px) {
    .how-to-apply .step-2 {
      float: right; } }
  .how-to-apply .step-2 .number, .how-to-apply .step-2 .link a {
    background-color: #FAB304; }
  .how-to-apply .step-3 {
    margin: 0 auto !important;
    clear: both; }
    .how-to-apply .step-3 .number, .how-to-apply .step-3 .link a {
      background-color: #ADC33D; }

.latest-post {
  background-color: #3e4160;
  margin-bottom: 2rem; }
  @media (min-width: 680px) {
    .latest-post {
      margin-bottom: 5rem;
      padding: 0 0 0 2rem; } }
  .latest-post .latest-post-col {
    width: 100%;
    float: left;
    overflow: hidden; }
    @media (min-width: 680px) {
      .latest-post .latest-post-col {
        width: 50%;
        height: 40rem; } }
  .latest-post h2 {
    font-size: 24px;
    font-size: 2.4rem;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    color: #fff;
    background-color: #1C1E41;
    display: inline-block;
    padding: 20px; }
  .latest-post h3 {
    font-size: 32px;
    font-size: 3.2rem;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    margin-bottom: 30px;
    margin-bottom: 3rem; }
    .latest-post h3 a {
      color: #fff; }
  .latest-post .post-info {
    padding: 2rem 2rem 3rem;
    color: #fff; }
    @media (min-width: 680px) {
      .latest-post .post-info {
        padding: 2rem 2rem 2rem 0; } }
    @media (min-width: 1200px) {
      .latest-post .post-info {
        padding: 2rem; } }
  .latest-post .post-date {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 30px;
    margin-bottom: 3rem; }
  .latest-post .excerpt {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 20px;
    margin-bottom: 2rem; }
  .latest-post .red-btn {
    font-size: 18px;
    font-size: 1.8rem;
    padding: 5px 10px; }
  .latest-post .latest-post-thumbnail-col {
    display: none; }
    @media (min-width: 680px) {
      .latest-post .latest-post-thumbnail-col {
        display: block; } }
    .latest-post .latest-post-thumbnail-col .latest-post-thumbnail {
      height: 100%;
      background-size: cover;
      background-position: center; }

.post-filter {
  font-size: 14px;
  font-size: 1.4rem;
  background-color: #6688AD;
  line-height: 36px;
  padding-left: 15px;
  padding-left: 1.5rem;
  color: #fff; }
  .post-filter .filter-label {
    margin-right: 10px;
    margin-right: 1rem; }
  .post-filter label {
    margin-right: 15px;
    margin-right: 1.5rem; }
  .post-filter .filter-order {
    padding-bottom: 1rem; }
    @media (min-width: 680px) {
      .post-filter .filter-order {
        padding-bottom: 0;
        float: right; } }
    .post-filter .filter-order select {
      height: 3.7rem;
      margin-left: 0.5rem;
      border: 1px solid #d6d6d6; }

.post-archive ul {
  margin: 0 !important; }

.post-archive li {
  overflow: hidden;
  padding-bottom: 15px;
  padding-bottom: 1.5rem;
  margin-bottom: 15px;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #d5d5d5; }
  .post-archive li:last-child {
    border-bottom: none; }
  .post-archive li .post-image {
    position: relative;
    width: 100%;
    margin-bottom: 2rem; }
    @media (min-width: 680px) {
      .post-archive li .post-image {
        float: left;
        width: 20rem;
        margin-bottom: 0; } }
    @media (min-width: 768px) {
      .post-archive li .post-image {
        width: 30rem; } }
    @media (min-width: 1200px) {
      .post-archive li .post-image {
        width: 40rem; } }
    .post-archive li .post-image img {
      width: 100%;
      height: auto; }
  .post-archive li .posted {
    font-size: 14px;
    font-size: 1.4rem;
    position: absolute;
    background-color: #fff;
    color: #000;
    top: 10px;
    top: 1rem;
    left: 0;
    padding: 10px;
    padding: 1rem; }
  @media (min-width: 680px) {
    .post-archive li .post-info {
      margin-left: 25rem; } }
  @media (min-width: 768px) {
    .post-archive li .post-info {
      margin-left: 35rem; } }
  @media (min-width: 1200px) {
    .post-archive li .post-info {
      margin-left: 44rem; } }
  .post-archive li .post-info p {
    font-size: 16px;
    font-size: 1.6rem;
    color: #000;
    line-height: 1.7; }
  .post-archive li .post-info span {
    padding: 5px 10px;
    padding: 0.5rem 1rem; }
  .post-archive li h3 {
    font-size: 32px;
    font-size: 3.2rem;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    color: #1C1E41;
    margin-bottom: 2rem; }
    @media (min-width: 960px) {
      .post-archive li h3 {
        margin-top: 3rem; } }

.posted-by {
  font-size: 16px;
  font-size: 1.6rem;
  color: #706F6F;
  margin-bottom: 60px;
  margin-bottom: 6rem; }

.related-articles:after {
  content: "";
  display: table;
  clear: both; }

.related-articles h2 {
  display: none; }
  @media (min-width: 680px) {
    .related-articles h2 {
      font-size: 32px;
      font-size: 3.2rem;
      font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
      display: block;
      color: #1C1E41;
      text-align: center;
      margin-bottom: 40px;
      margin-bottom: 4rem; } }
  @media (min-width: 1200px) {
    .related-articles h2 {
      margin-bottom: 50px;
      margin-bottom: 5rem; } }

.related-articles a {
  display: block;
  height: 390px;
  height: 39rem;
  margin-bottom: 30px;
  margin-bottom: 3rem;
  background-size: cover;
  background-position: center;
  position: relative; }
  .related-articles a::after {
    content: "";
    background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
    /* IE6-9 */
    opacity: 0.25;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute; }
  @media (min-width: 680px) {
    .related-articles a {
      float: left;
      width: 49%;
      margin-right: 2%; }
      .related-articles a:nth-child(2n) {
        margin-right: 0; } }
  @media (min-width: 1200px) {
    .related-articles a {
      float: left;
      width: 32%; }
      .related-articles a:nth-child(2n) {
        margin-right: 2%; }
      .related-articles a:nth-child(3n) {
        margin-right: 0; } }
  .related-articles a h3 {
    font-size: 32px;
    font-size: 3.2rem;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    color: #fff;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    z-index: 10; }
  .related-articles a span {
    background-image: url("../images/right-arrow.svg");
    background-repeat: no-repeat;
    background-color: #E83E31;
    background-position: center;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 6rem;
    height: 6rem;
    z-index: 10; }
    .ie-9 .related-articles a span {
      background-image: url("../images/right-arrow.png"); }
  .related-articles a .posted {
    font-size: 14px;
    font-size: 1.4rem;
    position: absolute;
    background-color: #fff;
    color: #000;
    top: 10px;
    top: 1rem;
    left: 0;
    padding: 10px;
    padding: 1rem;
    z-index: 10; }

.post-type-archive-partner .partners {
  border-bottom: 2px solid #d5d5d5;
  padding-bottom: 60px;
  padding-bottom: 6rem;
  margin-bottom: 60px;
  margin-bottom: 6rem; }
  .post-type-archive-partner .partners ul {
    width: 100%; }
  .post-type-archive-partner .partners li {
    width: 100%;
    float: none;
    margin-bottom: 4rem; }
    @media (min-width: 680px) {
      .post-type-archive-partner .partners li {
        margin: 0 5%;
        width: 40%; } }
    @media (min-width: 960px) {
      .post-type-archive-partner .partners li {
        width: 49%;
        margin: 0; } }
    .post-type-archive-partner .partners li .partner-image {
      position: relative;
      margin-bottom: 2rem; }
      @media (min-width: 680px) {
        .post-type-archive-partner .partners li .partner-image {
          height: 220px;
          margin-bottom: 6rem; } }
    .post-type-archive-partner .partners li img {
      max-width: 100%;
      width: auto;
      height: auto;
      max-height: 100%; }
      @media (min-width: 680px) {
        .post-type-archive-partner .partners li img {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%); } }
    .post-type-archive-partner .partners li .partner-excerpt {
      display: none; }
      @media (min-width: 680px) {
        .post-type-archive-partner .partners li .partner-excerpt {
          font-size: 18px;
          font-size: 1.8rem;
          display: block;
          max-width: 500px;
          max-width: 50rem;
          margin: 0 auto;
          color: #1C1E41;
          margin-bottom: 40px;
          margin-bottom: 4rem; } }
    .post-type-archive-partner .partners li .red-btn {
      font-size: 18px;
      font-size: 1.8rem;
      padding: 10px 15px; }

.equipment-manufacturers.partners {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important; }
  @media (min-width: 680px) {
    .equipment-manufacturers.partners {
      margin-bottom: 6rem !important;
      padding-bottom: 6rem !important; } }
  .equipment-manufacturers.partners h2 {
    font-size: 18px;
    font-size: 1.8rem;
    color: #1C1E41;
    text-align: center;
    padding: 0;
    margin-bottom: 4rem; }
    @media (min-width: 680px) {
      .equipment-manufacturers.partners h2 {
        font-size: 24px;
        font-size: 2.4rem;
        margin-bottom: 0; } }
    @media (min-width: 960px) {
      .equipment-manufacturers.partners h2 {
        font-size: 32px;
        font-size: 3.2rem; } }
  .equipment-manufacturers.partners li {
    width: 90%;
    margin-top: 4rem; }
    @media (min-width: 960px) {
      .equipment-manufacturers.partners li {
        width: 33%; } }
    .equipment-manufacturers.partners li .partner-image {
      margin-bottom: 40px !important;
      margin-bottom: 4rem !important; }
      @media (min-width: 680px) {
        .equipment-manufacturers.partners li .partner-image {
          height: 120px !important; } }
    .equipment-manufacturers.partners li .web-link {
      font-size: 18px;
      font-size: 1.8rem;
      color: #1C1E41; }

.horizontal-stings {
  display: none; }
  .horizontal-stings:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 960px) {
    .horizontal-stings {
      display: block; } }
  .horizontal-stings .hire-a-guide {
    float: left;
    width: 48%;
    height: 30rem;
    background-image: url(../images/hire-guide-horz-bg.jpg); }
    .horizontal-stings .hire-a-guide h2 {
      margin-bottom: 6rem; }
    .horizontal-stings .hire-a-guide .select-wrapper {
      float: left;
      width: 48%; }
      .horizontal-stings .hire-a-guide .select-wrapper.activity-select {
        margin-right: 4%; }
  .horizontal-stings .spotlight {
    float: right;
    width: 48%;
    height: 300px;
    height: 30rem; }

.partner-intro-wrapper {
  padding-top: 2rem;
  min-height: 58rem;
  margin-bottom: 3rem; }
  .partner-intro-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 680px) {
    .partner-intro-wrapper {
      min-height: 67rem;
      margin-bottom: 10rem; } }
  @media (min-width: 960px) {
    .partner-intro-wrapper {
      padding-top: 10rem;
      min-height: 73rem; } }
  .partner-intro-wrapper .partner-logo {
    text-align: center; }
    @media (min-width: 680px) {
      .partner-intro-wrapper .partner-logo {
        float: left;
        width: 50%; } }
    .partner-intro-wrapper .partner-logo img {
      width: auto;
      max-height: 12rem; }
      @media (min-width: 680px) {
        .partner-intro-wrapper .partner-logo img {
          max-height: none; } }
  .partner-intro-wrapper .partner-intro {
    width: 90%;
    margin: 0 auto;
    text-align: center; }
    @media (min-width: 680px) {
      .partner-intro-wrapper .partner-intro {
        width: 50%;
        float: left;
        text-align: left; } }
    .partner-intro-wrapper .partner-intro h1 {
      font-size: 32px;
      font-size: 3.2rem;
      font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
      color: #fff;
      margin-bottom: 3rem; }
      @media (min-width: 680px) {
        .partner-intro-wrapper .partner-intro h1 {
          font-size: 56px;
          font-size: 5.6rem; } }
    .partner-intro-wrapper .partner-intro p.intro-text {
      font-size: 14px;
      font-size: 1.4rem;
      color: #fff;
      line-height: 1.6;
      margin-bottom: 4rem; }
      @media (min-width: 680px) {
        .partner-intro-wrapper .partner-intro p.intro-text {
          font-size: 18px;
          font-size: 1.8rem;
          margin-bottom: 8rem; } }
    .partner-intro-wrapper .partner-intro .quote {
      font-size: 18px;
      font-size: 1.8rem;
      font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif;
      color: #fff;
      line-height: 1.6; }
      @media (min-width: 680px) {
        .partner-intro-wrapper .partner-intro .quote {
          font-size: 24px;
          font-size: 2.4rem; } }

.partner-content {
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif;
  text-align: center;
  margin-bottom: 8rem;
  color: #1C1E41;
  max-width: 100rem; }
  .partner-content p {
    margin-bottom: 2rem; }
    .partner-content p:last-child {
      margin-bottom: 0; }

.media-carousel {
  margin-bottom: 100px;
  margin-bottom: 10rem; }
  .media-carousel .slide {
    display: none; }
    .media-carousel .slide:first-child {
      display: block; }
    .media-carousel .slide img {
      width: 100%;
      height: auto; }

.partner-social-wrapper {
  background-color: #EFEFEF; }

.partner-social ul {
  list-style: none;
  height: 400px;
  height: 40rem; }

.partner-social li {
  float: left;
  width: 25%; }
  .partner-social li a.wrap, .partner-social li span.wrap {
    display: block;
    height: 400px;
    height: 40rem; }
  .partner-social li.twitter {
    background-color: #3AAAE1; }
    .partner-social li.twitter .social-icon {
      width: 40%;
      display: block;
      margin: 0 auto; }
    .partner-social li.twitter .social-text {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%); }
  .partner-social li.instagram {
    position: relative;
    background-size: 200%;
    background-position: center; }
    .partner-social li.instagram .social-icon {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 40px;
      width: 4rem;
      height: 40px;
      height: 4rem; }
    .partner-social li.instagram .social-body {
      display: none; }

.partner-social .social-text {
  font-size: 16px;
  font-size: 1.6rem;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: #fff; }
  .partner-social .social-text a {
    color: #fff; }

.partner-social button {
  display: none !important; }

.website-link {
  padding: 120px 0;
  padding: 12rem 0;
  text-align: center; }
  .website-link .partner-logo {
    display: inline-block;
    max-width: 120px;
    text-align: center;
    vertical-align: middle;
    margin-right: 30px;
    margin-right: 3rem; }
    .website-link .partner-logo img {
      max-width: 100%;
      height: auto; }
  .website-link a {
    font-size: 24px;
    font-size: 2.4rem;
    font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif;
    color: #1C1E41;
    display: inline-block;
    vertical-align: middle;
    text-align: left; }

.ie-9 .explore-this-section a.explore-toggle span.arrow {
  background-image: url("../images/right-arrow.svg");
  background-repeat: no-repeat; }
  .ie-9 .ie-9 .explore-this-section a.explore-toggle span.arrow {
    background-image: url("../images/right-arrow.png"); }

.explore-this-section {
  float: right;
  position: relative; }
  .explore-this-section a.explore-toggle {
    font-size: 14px;
    font-size: 1.4rem;
    display: block;
    background: #E83E31;
    color: #fff;
    padding: 0 0.5rem;
    line-height: 2.5rem; }
    @media (min-width: 680px) {
      .explore-this-section a.explore-toggle {
        font-size: 18px;
        font-size: 1.8rem;
        padding: 0 1rem;
        line-height: 4rem; } }
    @media (min-width: 960px) {
      .explore-this-section a.explore-toggle {
        padding: 0 1rem;
        line-height: 5rem; } }
    .explore-this-section a.explore-toggle:hover {
      background: #cf2417; }
    .explore-this-section a.explore-toggle span.section-text {
      display: none; }
      @media (min-width: 960px) {
        .explore-this-section a.explore-toggle span.section-text {
          display: inline; } }
    .explore-this-section a.explore-toggle span.arrow {
      background-image: url("../images/right-arrow.svg");
      background-repeat: no-repeat;
      display: inline-block;
      width: 7px;
      height: 14px;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      margin: 0 0.5rem 0 1rem; }
      .ie-9 .explore-this-section a.explore-toggle span.arrow {
        background-image: url("../images/right-arrow.png"); }
      @media (min-width: 960px) {
        .explore-this-section a.explore-toggle span.arrow {
          margin: 0 0.5rem 0 2rem; } }
  .explore-this-section li a {
    font-size: 14px;
    font-size: 1.4rem;
    display: block;
    background: #E83E31;
    color: #fff;
    padding: 0 0.5rem;
    line-height: 2.5rem; }
    @media (min-width: 680px) {
      .explore-this-section li a {
        font-size: 18px;
        font-size: 1.8rem;
        padding: 0 1rem;
        line-height: 4rem; } }
    @media (min-width: 960px) {
      .explore-this-section li a {
        padding: 1rem;
        line-height: 5rem; } }
    .explore-this-section li a:hover {
      background: #cf2417; }
  .explore-this-section ul {
    display: none;
    list-style: outside none none;
    position: absolute;
    width: 250%;
    z-index: 100;
    right: 0; }
    .explore-this-section ul a {
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 3rem; }

.logout-btn {
  font-size: 18px;
  font-size: 1.8rem;
  float: right; }
  .logout-btn a {
    padding: 0.5rem 1rem; }

.submit-incident-panel {
  background-color: #fff;
  border: 1px solid #d5d5d5;
  padding: 5rem;
  margin-bottom: 2rem; }
  .submit-incident-panel:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 680px) {
    .submit-incident-panel {
      margin-bottom: 4rem; } }
  @media (min-width: 960px) {
    .submit-incident-panel {
      margin-bottom: 8rem; } }
  .submit-incident-panel .submit-icon {
    font-size: 18px;
    font-size: 1.8rem;
    background: url(../images/mountain-icon-red.png) center no-repeat;
    background-size: contain;
    color: #fff;
    text-align: center;
    width: 15rem;
    height: 15rem;
    display: block;
    margin: 0 auto; }
    @media (min-width: 680px) {
      .submit-incident-panel .submit-icon {
        float: left; } }
    @media (min-width: 960px) {
      .submit-incident-panel .submit-icon {
        font-size: 24px;
        font-size: 2.4rem;
        width: 20rem;
        height: 20rem; } }
    .submit-incident-panel .submit-icon span {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      display: block;
      width: 70%;
      margin: 0 auto; }
  .submit-incident-panel .incident-content {
    padding-top: 2rem; }
    @media (min-width: 680px) {
      .submit-incident-panel .incident-content {
        margin-left: 24rem; } }
    @media (min-width: 960px) {
      .submit-incident-panel .incident-content {
        margin-left: 25rem; } }
    .submit-incident-panel .incident-content h2 {
      font-size: 24px;
      font-size: 2.4rem;
      font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
      color: #1C1E41;
      margin-bottom: 4rem;
      text-align: center; }
    .submit-incident-panel .incident-content ul {
      list-style: none; }
    .submit-incident-panel .incident-content li {
      font-size: 18px;
      font-size: 1.8rem;
      color: #9D9D9C;
      margin-bottom: 4rem; }
      .submit-incident-panel .incident-content li span {
        display: block;
        margin: 0 auto 1.5rem;
        width: 2rem;
        height: 2rem; }
        @media (min-width: 680px) {
          .submit-incident-panel .incident-content li span {
            float: left;
            width: 4rem;
            height: 4rem;
            margin-right: 2rem; } }
      @media (min-width: 680px) {
        .submit-incident-panel .incident-content li .wte_text {
          margin-left: 6rem; } }
    .submit-incident-panel .incident-content p.contact {
      font-size: 24px;
      font-size: 2.4rem;
      background-color: #EFEFEF;
      font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
      color: #E83E31;
      padding: 1rem 2rem;
      float: left; }

.main-col .submit-incident-panel {
  padding: 4rem 2rem; }
  .main-col .submit-incident-panel .submit-icon {
    display: none; }

.main-col ul {
  margin: 1.5rem; }
  .main-col ul li {
    margin-bottom: 1rem; }

.main-col .incident-content {
  margin-left: 0;
  padding-top: 0; }
  .main-col .incident-content ul {
    margin: 0; }
  .main-col .incident-content p {
    margin-bottom: 5rem; }

.main-col .acf-fields > .acf-field {
  padding: 1.5rem 0;
  border-top: none; }

.main-col .acf-field .acf-label label {
  color: #1C1E41 !important;
  font-weight: normal; }

.main-col .acf-field input[type="text"], .main-col .acf-field input[type="password"], .main-col .acf-field input[type="number"], .main-col .acf-field input[type="search"], .main-col .acf-field input[type="email"], .main-col .acf-field input[type="url"], .main-col .acf-field textarea, .main-col .acf-field select {
  border: 1px solid #9D9D9C; }

.my-account-links {
  width: 70%;
  margin: 0 auto;
  list-style: none; }
  .my-account-links:after {
    content: "";
    display: table;
    clear: both; }
  .my-account-links li {
    background: url(../images/mountain-icon-blue.png) center no-repeat;
    background-size: contain;
    width: 20rem;
    margin: 0 auto; }
    @media (min-width: 680px) {
      .my-account-links li {
        float: left;
        width: 45%;
        margin-right: 5%; } }
    @media (min-width: 960px) {
      .my-account-links li {
        width: 30%; }
        .my-account-links li:nth-child(3n) {
          margin-right: 0; }
        .my-account-links li:nth-child(7n) {
          margin-left: 17%; } }
    .my-account-links li a {
      font-size: 24px;
      font-size: 2.4rem;
      display: block;
      text-align: center;
      color: #fff;
      height: 27rem; }
      .my-account-links li a span {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        width: 65%;
        margin: 0 auto;
        display: block; }

.admin-panel {
  padding: 4rem 2rem;
  background: #fff;
  border: 1px solid #d5d5d5; }

.edit-profile h2 {
  font-size: 24px;
  font-size: 2.4rem;
  color: #1C1E41;
  font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif; }

.edit-profile h3 {
  font-size: 18px;
  font-size: 1.8rem;
  color: #1C1E41;
  background-color: #EFEFEF;
  padding: 1.5rem 1rem;
  margin-bottom: 3rem; }

.edit-profile table, .edit-profile .tml-profile .tml-form-table {
  margin-bottom: 1rem;
  width: 100%; }
  .edit-profile table th, .edit-profile .tml-profile .tml-form-table th {
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: normal;
    color: #1C1E41; }
  .edit-profile table input, .edit-profile table textarea, .edit-profile .tml-profile .tml-form-table input, .edit-profile .tml-profile .tml-form-table textarea {
    border: 1px solid #9d9d9c;
    padding: 3px 5px; }
  .edit-profile table td, .edit-profile .tml-profile .tml-form-table td {
    padding: 5px 10px 20px 0; }

.button-primary, .wp-generate-pw {
  font-size: 16px;
  font-size: 1.6rem;
  background-image: url("../images/right-arrow.svg");
  background-repeat: no-repeat;
  background-position: 92% center;
  background-color: #E83E31;
  color: #fff;
  border: none;
  padding: 1.5rem 4rem 1.5rem 1rem; }
  .ie-9 .button-primary, .ie-9 .wp-generate-pw {
    background-image: url("../images/right-arrow.png"); }

.incident-list {
  padding-top: 2rem; }
  .incident-list table {
    width: 100%; }
  .incident-list th, .incident-list td {
    font-weight: normal;
    padding: 2rem 2rem 2rem 0;
    text-align: left;
    border-bottom: 1px solid #d5d5d5; }
  .incident-list .incident-options {
    text-align: right;
    padding-right: 0; }

.downloads-panel {
  padding: 2rem; }
  .downloads-panel:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 680px) {
    .downloads-panel {
      padding: 4rem; } }

@media (min-width: 680px) {
  .download-filters {
    width: 16rem;
    float: left; } }

@media (min-width: 1200px) {
  .download-filters {
    width: 23rem; } }

.download-filters h2 {
  font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
  color: #1C1E41;
  margin-bottom: 2rem; }
  @media (min-width: 680px) {
    .download-filters h2 {
      margin-bottom: 4rem; } }

.download-filters ul {
  list-style: none; }

.download-filters li {
  margin-bottom: 2rem; }
  .download-filters li input {
    margin-right: 1rem; }

@media (min-width: 680px) {
  .download-list {
    margin-left: 20rem; } }

@media (min-width: 1200px) {
  .download-list {
    margin-left: 27rem; } }

.download-list .preview-col {
  display: none; }
  @media (min-width: 680px) {
    .download-list .preview-col {
      display: block; } }

.download-list h2 {
  font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
  color: #1C1E41;
  margin-bottom: 1rem; }
  @media (min-width: 680px) {
    .download-list h2 {
      margin-bottom: 2rem; } }

.download-list table {
  width: 100%; }

.download-list th, .download-list td {
  font-size: 16px;
  font-size: 1.6rem;
  padding: 2rem 0;
  border-bottom: 1px solid #d5d5d5; }

.download-list th {
  font-weight: normal;
  color: #1C1E41;
  text-align: left; }

.download-list td {
  color: #9D9D9C; }

.download-list .download-options {
  text-align: right; }

.main-col .wpcf7-form-control-wrap {
  display: block;
  margin-top: 1rem; }

.main-col .wpcf7-text, .main-col .wpcf7-textarea {
  border: 1px solid #d5d5d5;
  padding: 0.5rem; }

.main-col .wpcf7-submit {
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif;
  background: #1C1E41;
  border: none;
  color: #fff;
  padding: 12px 10px;
  padding: 1.2rem 1rem;
  cursor: pointer; }

#login-popup {
  display: none;
  position: fixed;
  z-index: 10000;
  top: 50%;
  left: 50%;
  width: 40rem;
  height: 36rem;
  margin-left: -20rem;
  /* negative half of width above */
  margin-top: -18rem;
  /* negative half of height above */
  background: #e83e31;
  color: #FFF;
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif; }
  #login-popup .close {
    float: right;
    color: #FFF;
    margin: 1rem; }
  #login-popup h2 {
    margin: 2rem 2rem 4rem 2rem; }
  #login-popup form {
    margin: 2rem; }
  #login-popup .input {
    width: 100%;
    padding: 1rem;
    border: 0; }
  #login-popup p {
    margin: 2rem 0; }
  #login-popup #wp-submit {
    display: block;
    width: 100%;
    padding: 1rem;
    border: 0;
    background: shade(#f33633, 30%);
    color: #fff; }

#menu-my-account-sidebar {
  font-size: 16px;
  font-size: 1.6rem;
  list-style: none;
  margin: 0; }
  #menu-my-account-sidebar li a {
    padding: 1.5rem 1.5rem;
    display: block;
    border-top: 1px solid #69677f;
    background: #75728b;
    color: #fff; }
  #menu-my-account-sidebar li.current-menu-item a {
    background: #1C1E41 !important; }
  #menu-my-account-sidebar li:first-child a {
    background: #ED1C24;
    margin-bottom: 1rem;
    border-top: none; }

.pagination {
  clear: both; }
  .pagination ul {
    float: left;
    list-style: none;
    margin: 4rem 0 0 0; }
    .pagination ul li {
      float: left; }
      .pagination ul li a {
        padding: 1rem;
        font-weight: bold; }
      .pagination ul li.active a {
        background: #6688AD;
        color: #fff; }

.training-progress h3 {
  font-size: 32px;
  font-size: 3.2rem;
  font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
  margin-bottom: 2rem;
  text-align: center; }

.training-progress ul li {
  list-style: none;
  margin: 0;
  overflow: hidden; }
  @media (min-width: 960px) {
    .training-progress ul li {
      float: left;
      width: 32%;
      margin-right: 1%; } }
  .training-progress ul li h4 {
    font-size: 18px;
    font-size: 1.8rem;
    background: #EFEFEF;
    padding: 1rem; }
  .training-progress ul li.open h4 {
    background-color: #E83E31;
    color: #fff; }
  .training-progress ul li ol {
    display: none;
    background: #EFEFEF;
    padding: 2rem 1rem; }
    .training-progress ul li ol li {
      font-size: 16px;
      font-size: 1.6rem;
      background: url(../images/training-bg.png) top 3px left no-repeat;
      width: 100%;
      float: none;
      clear: both;
      padding-left: 3rem;
      color: #9D9D9C;
      padding-bottom: 2rem; }
      @media (min-width: 1200px) {
        .training-progress ul li ol li {
          padding-bottom: 4rem; } }
      .training-progress ul li ol li:last-child {
        background: url(../images/training-bg-last.png) top 3px left no-repeat; }
      .training-progress ul li ol li h5 {
        font-size: 18px;
        font-size: 1.8rem;
        font-weight: bold;
        color: #1C1E41;
        margin-bottom: 2rem;
        line-height: 1; }
      .training-progress ul li ol li .location, .training-progress ul li ol li .date, .training-progress ul li ol li .duration {
        padding-left: 2rem; }
      .training-progress ul li ol li .duration {
        background: url(../images/clock.png) top 3px left no-repeat;
        margin-bottom: 1rem; }
      @media (min-width: 1200px) {
        .training-progress ul li ol li .location, .training-progress ul li ol li .date {
          float: left; } }
      .training-progress ul li ol li .location {
        background: url(../images/pin.png) top 3px left no-repeat;
        margin-bottom: 1rem; }
        @media (min-width: 1200px) {
          .training-progress ul li ol li .location {
            margin-bottom: 0; }
            .training-progress ul li ol li .location + .date {
              margin-left: 2rem; } }
      .training-progress ul li ol li .date {
        background: url(../images/calendar.png) top 3px left no-repeat;
        padding-left: 2rem; }
  .training-progress ul li.open ol {
    display: block; }

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }
  .video-wrapper iframe, .video-wrapper object, .video-wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.our-story--header {
  position: absolute;
  z-index: 100;
  top: 0;
  width: 100%;
  padding: 2rem 1rem; }
  @media (min-width: 320px) {
    .our-story--header {
      padding: 2rem; } }
  @media (min-width: 1200px) {
    .our-story--header {
      padding: 5rem; } }
  .our-story--header h1 {
    height: 2rem;
    float: left;
    font-family: "Rockwell W01", Helvetica, Arial sans-serif;
    line-height: 2rem;
    font-size: 12px;
    font-size: 1.2rem; }

.toggle--story-nav {
  display: block;
  width: 20px;
  height: 15px;
  float: right; }
  @media (min-width: 960px) {
    .toggle--story-nav:hover span {
      background-color: #1C1E41; } }
  .toggle--story-nav span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #E83E31; }

.line-1 {
  margin: 0 0 3px; }

.line-2 {
  margin: 0 0 3px; }

.story-nav {
  display: none;
  position: absolute;
  width: 75%;
  top: 5rem;
  right: 1rem;
  background-color: #E83E31; }
  .story-nav ul {
    list-style-type: none; }
  .story-nav a {
    display: block;
    padding: 1rem;
    font-family: "Rockwell W01", Helvetica, Arial sans-serif;
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    font-size: 1.2rem; }

.date-section {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 0 1rem; }
  @media (min-width: 320px) {
    .date-section {
      padding: 0 2rem; } }
  @media (min-width: 1200px) {
    .date-section {
      padding: 0 5rem; } }
  @media (min-width: 960px) and (orientation: landscape) {
    .date-section:after {
      content: "";
      position: absolute;
      z-index: 0;
      top: 0;
      left: 50%;
      width: 0;
      height: 100%; } }
  .date-section:before {
    z-index: 0;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .date-section.light:before {
    background-color: rgba(255, 255, 255, 0.66); }
  @media (min-width: 960px) and (orientation: landscape) {
    .date-section.light:after {
      border-left: 1px solid rgba(28, 30, 65, 0.5); } }
  .date-section.light h2 {
    color: rgba(28, 30, 65, 0.5); }
  .date-section.light h3 {
    border-bottom: 1px solid #1C1E41; }
  .date-section.light h3, .date-section.light p {
    color: #1C1E41; }
  .date-section.light .next-btn {
    color: #1C1E41; }
    .date-section.light .next-btn:after {
      border-color: #1C1E41 transparent transparent transparent; }
  @media (min-width: 960px) and (orientation: landscape) {
    .date-section.light .date-section--icon:after {
      border-bottom: 1px solid rgba(28, 30, 65, 0.5); } }
  @media (min-width: 960px) and (orientation: landscape) {
    .date-section.light i {
      background-color: #fff; } }
  .date-section.light svg {
    fill: #1C1E41; }
  .date-section.dark:before {
    background-color: rgba(28, 30, 65, 0.75); }
  @media (min-width: 960px) and (orientation: landscape) {
    .date-section.dark:after {
      border-left: 1px solid rgba(255, 255, 255, 0.5); } }
  .date-section.dark h2 {
    color: rgba(255, 255, 255, 0.5); }
  .date-section.dark h3 {
    border-bottom: 1px solid #fff; }
  .date-section.dark h3, .date-section.dark p {
    color: #fff; }
  .date-section.dark .next-btn {
    color: #fff; }
    .date-section.dark .next-btn:after {
      border-color: #fff transparent transparent transparent; }
  @media (min-width: 960px) and (orientation: landscape) {
    .date-section.dark .date-section--icon:after {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); } }
  @media (min-width: 960px) and (orientation: landscape) {
    .date-section.dark i {
      background-color: #1C1E41; } }
  .date-section.dark svg {
    fill: #fff; }

.date-section--content {
  position: relative;
  z-index: 2; }
  @media (min-width: 568px) and (orientation: landscape) {
    .date-section--content {
      width: 90%; } }
  @media (min-width: 680px) and (orientation: landscape) {
    .date-section--content {
      width: 75%; } }
  @media (min-width: 960px) and (orientation: landscape) {
    .date-section--content {
      width: 100%;
      height: 100%; } }

@media (min-width: 960px) and (orientation: landscape) {
  .date-section--year {
    position: absolute;
    width: 50%; } }

.date-section--year h2 {
  font-family: "Rockwell W01", Helvetica, Arial sans-serif;
  text-align: center;
  letter-spacing: -0.05em;
  font-size: 50px;
  font-size: 5rem; }
  @media (min-width: 568px) {
    .date-section--year h2 {
      font-size: 80px;
      font-size: 8rem; } }
  @media (min-width: 960px) {
    .date-section--year h2 {
      font-size: 120px;
      font-size: 12rem; } }
  @media (min-width: 1200px) {
    .date-section--year h2 {
      font-size: 160px;
      font-size: 16rem; } }

@media (min-width: 960px) and (orientation: landscape) {
  .left .date-section--year {
    top: 10rem; } }

@media (min-width: 960px) {
  .left .date-section--year h2 {
    text-align: left; } }

@media (min-width: 960px) and (orientation: landscape) {
  .right .date-section--year {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%); } }

@media (min-width: 960px) {
  .right .date-section--year h2 {
    text-align: right; } }

.date-section--icon {
  width: 2rem;
  height: 3rem;
  margin: 0 auto;
  padding: 1rem 0; }
  @media (min-width: 960px) and (orientation: landscape) {
    .date-section--icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      width: 33.333%;
      height: 3rem;
      margin: 0;
      padding: 0; }
      .date-section--icon:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 0;
        width: 100%; } }
  .date-section--icon i {
    width: 2rem;
    height: 2rem;
    display: block; }
    @media (min-width: 960px) and (orientation: landscape) {
      .date-section--icon i {
        position: absolute;
        width: 3rem;
        height: 3rem;
        padding: 0.5rem;
        border-radius: 100%;
        background-color: #1C1E41; } }
  .date-section--icon svg {
    width: 2rem;
    height: 2rem; }

@media (min-width: 960px) and (orientation: landscape) {
  .left .date-section--icon {
    right: 0; } }

@media (min-width: 960px) and (orientation: landscape) {
  .left .date-section--icon i {
    right: -1.5rem; } }

@media (min-width: 960px) and (orientation: landscape) {
  .right .date-section--icon {
    left: 0; } }

@media (min-width: 960px) and (orientation: landscape) {
  .right .date-section--icon i {
    left: -1.5rem; } }

.date-section--info {
  text-align: center; }
  @media (min-width: 960px) and (orientation: landscape) {
    .date-section--info {
      position: absolute;
      width: 40%;
      text-align: left; } }
  @media (min-width: 1200px) {
    .date-section--info {
      width: 33.333%; } }
  .date-section--info h3 {
    padding: 0 0 0.5rem;
    margin: 0 0 1rem;
    font-size: 20px;
    font-size: 2rem; }
  .date-section--info p {
    margin: 0 0 2rem;
    line-height: 1.5;
    font-size: 12px;
    font-size: 1.2rem; }
    @media (max-width: 568px) and (orientation: landscape) {
      .date-section--info p {
        font-size: 12px;
        font-size: 1.2rem; } }
    @media (min-width: 568px) and (orientation: all) {
      .date-section--info p {
        font-size: 12px;
        font-size: 1.2rem; } }

@media (min-width: 960px) and (orientation: landscape) {
  .left .date-section--info {
    left: 0;
    bottom: 5rem; } }

@media (min-width: 960px) and (orientation: landscape) {
  .right .date-section--info {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%); } }

.next-btn {
  position: relative;
  display: block;
  width: 6rem;
  height: 2rem;
  margin: 0 auto;
  font-family: "Rockwell W01", Helvetica, Arial sans-serif;
  text-decoration: none;
  line-height: 2rem;
  font-size: 12px;
  font-size: 1.2rem; }
  @media (min-width: 960px) and (orientation: landscape) {
    .next-btn {
      margin: 0; } }
  .next-btn:after {
    position: absolute;
    content: "";
    top: 45%;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px; }

.activity-guide-options {
  margin-bottom: 5rem; }
  .activity-guide-options a {
    font-size: 18px;
    font-size: 1.8rem;
    width: 100%;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    display: inline-block;
    background-color: #1C1E41;
    color: #fff;
    line-height: 6rem;
    padding-left: 2rem;
    background-image: url("../images/downarrow.svg");
    background-repeat: no-repeat;
    background-position: 97% 50%; }
    .ie-9 .activity-guide-options a {
      background-image: url("../images/downarrow.png"); }
    @media (min-width: 680px) {
      .activity-guide-options a {
        font-size: 24px;
        font-size: 2.4rem;
        width: 50%;
        line-height: 9rem; } }
    .activity-guide-options a:hover, .activity-guide-options a.active {
      background-color: #E83E31; }
    .activity-guide-options a.active {
      background-image: url("../images/uparrow.svg");
      background-repeat: no-repeat;
      background-position: 97% 50%; }
      .ie-9 .activity-guide-options a.active {
        background-image: url("../images/uparrow.png"); }

.find-an-activity-guide {
  display: none; }

.email-guides-form .form {
  width: 70%;
  margin: 0 auto;
  padding: 0; }

.email-guides-form h2 {
  font-size: 24px;
  font-size: 2.4rem;
  color: #1C1E41;
  font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif;
  margin-bottom: 2rem; }

.email-guides-form ul {
  list-style-type: none; }

.email-guides-form label {
  font-size: 16px;
  font-size: 1.6rem;
  display: block;
  color: #1C1E41;
  margin-bottom: 0.5rem; }

.email-guides-form input.text-input, .email-guides-form textarea {
  font-size: 16px;
  font-size: 1.6rem;
  border: 1px solid #d5d5d5;
  width: 100%;
  padding: 1.5rem 1rem;
  margin-bottom: 3rem; }

.email-guides-form textarea {
  height: 15rem; }

.email-guides-form .submit-area {
  background: none; }
  .email-guides-form .submit-area input {
    background-image: url("../images/right-arrow.svg");
    background-repeat: no-repeat;
    width: auto;
    background-color: #E83E31;
    background-position: 90% 50%;
    padding: 1.5rem 10rem 1.5rem 1rem; }
    .ie-9 .email-guides-form .submit-area input {
      background-image: url("../images/right-arrow.png"); }
    .email-guides-form .submit-area input:hover {
      background-color: #cf2417; }

.confirmation-message {
  font-size: 16px;
  font-size: 1.6rem;
  background-color: #fff;
  border: 1px solid #92BA3F;
  color: #706f6f;
  padding: 1rem;
  margin-bottom: 2.5rem; }

.activities-banner .carousel-stage {
  display: none; }
  @media (min-width: 680px) {
    .activities-banner .carousel-stage {
      display: block; } }

.carousel-stage {
  position: relative;
  height: 34rem;
  margin-bottom: 3rem; }
  @media (min-width: 680px) {
    .carousel-stage {
      height: 40rem; } }
  .carousel-stage .dots {
    display: none;
    position: absolute;
    z-index: 100000;
    bottom: 5rem;
    background: #1C1E41;
    padding: 0.5rem 1rem;
    right: 4rem; }
    @media (min-width: 680px) {
      .carousel-stage .dots {
        display: block; } }
    @media (min-width: 1200px) {
      .carousel-stage .dots {
        right: 0; } }
    .carousel-stage .dots span {
      border: 1px solid #fff;
      border-radius: 1rem;
      width: 1rem;
      height: 1rem;
      display: inline-block;
      margin-right: 0.5rem; }
      .carousel-stage .dots span.active {
        background: #fff; }
      .carousel-stage .dots span:last-child {
        margin-right: 0; }

.slick-dots {
  display: none;
  list-style-type: none; }
  @media (min-width: 680px) {
    .slick-dots {
      display: block;
      position: absolute;
      z-index: 5000;
      bottom: 0;
      background: #1C1E41;
      padding: 0.5rem 1rem;
      right: 0; } }
  .slick-dots li {
    float: left;
    margin: 0.25rem; }
  .slick-dots .slick-active button {
    background-color: white; }
  .slick-dots button {
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid #fff;
    border-radius: 100%;
    background: none;
    text-indent: -9999px; }
    .slick-dots button:hover {
      background-color: #fff; }

.vivid-credit {
  text-align: center; }
  @media (min-width: 680px) {
    .vivid-credit {
      text-align: right; } }

.guide-filters--outer {
  position: relative;
  margin: 0 0 3rem; }
  @media (min-width: 768px) {
    .guide-filters--outer {
      margin: 0 0 5rem; } }
  .guide-filters--outer h2 {
    margin: 0 0 2rem;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    text-align: center;
    color: #1C1E41;
    font-size: 32px;
    font-size: 3.2rem; }
  .guide-filters--outer p {
    display: block;
    padding: 1rem;
    text-align: center;
    font-size: 14px;
    font-size: 1.4rem; }
    @media (min-width: 768px) {
      .guide-filters--outer p {
        position: absolute;
        top: 8rem;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%); } }

.guide-filters {
  text-align: center; }
  @media (min-width: 768px) {
    .guide-filters {
      width: 50%;
      padding: 0 5rem;
      float: left; } }
  .guide-filters h3 {
    margin: 0 0 1rem;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    text-align: center;
    color: #1C1E41;
    font-size: 18px;
    font-size: 1.8rem; }
  .guide-filters .select-wrapper {
    background: url("../images/down-arrow.svg") no-repeat right 1rem center #fff;
    overflow: hidden;
    border: 1px solid #EFEFEF; }
    .guide-filters .select-wrapper select {
      font-size: 18px;
      font-size: 1.8rem;
      background: transparent;
      border: none;
      outline: none;
      width: 110%;
      padding: 1.5rem 1rem;
      color: #9D9D9C; }
  .guide-filters input {
    width: 100%;
    padding: 1.5rem 1rem;
    border: 1px solid #EFEFEF;
    background: url("../images/search-icon.svg") no-repeat right 1rem center #fff;
    font-size: 18px;
    font-size: 1.8rem; }

.sidebar-cta {
  position: relative;
  display: block;
  height: 25rem;
  padding: 2rem;
  margin: 0 0 2rem;
  background-size: cover;
  background-position: center; }
  .sidebar-cta h2 {
    position: relative;
    z-index: 2;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    color: #fff;
    font-size: 24px;
    font-size: 2.4rem; }
  .sidebar-cta:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%; }
  .sidebar-cta.blue:after {
    background-color: rgba(28, 30, 65, 0.66); }
  .sidebar-cta.red:after {
    background-color: rgba(232, 62, 49, 0.66); }
  .sidebar-cta:hover {
    text-decoration: none; }
    .sidebar-cta:hover .sidebar-cta-btn {
      background-color: #E83E31; }

.sidebar-cta-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: block;
  padding: 1rem;
  background-color: #1C1E41;
  color: #fff;
  font-family: "Foundry Form Serif W01 Bk", Arial, sans-serif;
  font-size: 14px;
  font-size: 1.4rem; }

div.wpcf7 .ajax-loader {
  display: none !important; }

.documents-list-outer {
  margin: 3rem 0; }
  .documents-list-outer h3 {
    margin: 0 0 2rem;
    font-family: "Foundry Form Serif W01 Demi", Arial, sans-serif;
    color: #1C1E41;
    font-size: 18px;
    font-size: 1.8rem; }

.documents-list {
  list-style-type: none; }
  .documents-list a {
    font-size: 14px;
    font-size: 1.4rem;
    display: block;
    color: #fff;
    padding: 0 0.5rem 0 2rem;
    line-height: 2.5rem;
    background: url("../images/download-icon.svg") no-repeat left 0.5rem center #E83E31;
    background-size: 10px auto; }
    @media (min-width: 680px) {
      .documents-list a {
        font-size: 18px;
        font-size: 1.8rem;
        padding: 0 1rem 0 4rem;
        line-height: 4rem;
        background-position: left 1rem center;
        background-size: 15px auto; } }
    @media (min-width: 960px) {
      .documents-list a {
        line-height: 5rem; } }
    .documents-list a:hover {
      background-color: #cf2417; }

.main-col .documents-list {
  margin: 0;
  padding: 0; }

.main-col .documents-list li {
  display: block;
  margin: 0 0 1rem;
  float: left;
  clear: both; }

.main-col .documents-list a {
  color: #fff; }
